import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { DepositRecord } from 'src/model/Deposit';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<DepositRecord>;
  navigateCallback: (record: DepositRecord) => void;
};

const DepositCard = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (record: DepositRecord) => () => {
    navigateCallback(record);
  };

  const elementMapFunction = (
    record: DepositRecord,
    index: number,
    array: Array<DepositRecord>,
  ) => (
    <div key={record.id}>
      <div className="flex flex-col gap-[4px] pt-[15px]">
        <div className="flex flex-row gap-[15px]">
          <Body size="m" className="text-text-table-thead">
            {t('record.desc.coin')}
          </Body>
          <Body size="m">{record.coin.toUpperCase()}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" className="text-text-table-thead">
            {t('record.desc.amount')}
          </Body>
          <Body size="m">{bnFormat(record.amount)}</Body>
        </div>
        <div className="mt-[6px] flex flex-col gap-[2px] text-text-table-thead">
          <Body size="s">{record.address}</Body>
          <Body size="s">{format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}</Body>
        </div>
        <div className="mt-[6px] flex justify-end">
          <Button appearance="text" onClick={onDetailClick(record)}>
            {t('record.act.detail')}
          </Button>
        </div>
      </div>
      <Divider
        theme="sub"
        className={classNames('mt-[13px]', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default DepositCard;
