import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import H3 from 'src/component/typography/H3';
import { NewAddressForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { createContact } from 'src/service/propertyService';

type Props = {
  open: boolean;
  handleClose: () => void;
  coin: string;
  network: string;
  address: string;
  onFinish: () => void;
};

const NewAddressModal = ({ open, handleClose, coin, network, address, onFinish }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newAddressFormMethods = useForm<NewAddressForm>();

  const onNewAddressFormSubmit = (data: NewAddressForm) => {
    handleClose();
    createContact({ coin, network, address, label: data.label })
      .then(() => {
        newAddressFormMethods.reset();
        onFinish();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form
        className="flex flex-col"
        methods={newAddressFormMethods}
        onSubmit={onNewAddressFormSubmit}
      >
        <H3>{t('withdrawal.act.saveAddress')}</H3>
        <div className="mt-[25px]">
          <FormInput
            label={t('withdrawal.desc.label')}
            name="label"
            maxLength={64}
            required
            asterisked
          />
        </div>
        <div className="mt-[40px] text-end">
          <Button type="submit">{t('withdrawal.act.create')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NewAddressModal;
