import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { DepositRecord } from 'src/model/Deposit';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<DepositRecord>;
  navigateCallback: (record: DepositRecord) => void;
};

const DepositTable = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (record: DepositRecord) => () => {
    navigateCallback(record);
  };

  const elementMapFunction = (
    record: DepositRecord,
    index: number,
    array: Array<DepositRecord>,
  ) => (
    <div key={record.id} className="flex flex-col">
      <div className="flex flex-row">
        <Body size="m" className="box-border w-[10%] break-words pr-[10px]">
          {record.coin.toUpperCase()}
        </Body>
        <Body size="m" className="box-border w-[20%] break-words pr-[10px]">
          {bnFormat(record.amount)}
        </Body>
        <Body size="m" className="box-border w-[20%] break-words pr-[10px]">
          {format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </Body>
        <Body size="m" className="box-border w-[40%] break-words pr-[10px]">
          {record.address}
        </Body>
        <div className="box-border flex w-[10%] items-center justify-end break-words pr-[10px] text-center">
          <Button appearance="text" onClick={onDetailClick(record)}>
            {t('record.act.detail')}
          </Button>
        </div>
      </div>
      <Divider
        theme="sub"
        className={classNames('my-[15px]', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex text-text-table-thead">
        <Body size="m" bold className="w-[10%]">
          {t('record.desc.coin')}
        </Body>
        <Body size="m" bold className="w-[20%]">
          {t('record.desc.amount')}
        </Body>
        <Body size="m" bold className="w-[20%]">
          {t('record.desc.date')}
        </Body>
        <Body size="m" bold className="w-[40%]">
          {t('record.desc.address')}
        </Body>
      </div>
      <Divider theme="sub" className="my-[15px]" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default DepositTable;
