import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import PublicBar from 'src/component/PublicBar';
import { Page } from 'src/constant/Page';
import { RootState } from 'src/redux/store';
import Footer from './component/Footer';

const LandingBid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogin } = useSelector((state: RootState) => state.auth);

  return (
    <div>
      <PublicBar />
      <div className="px-[15px] py-0 xs:mx-auto xs:my-0 xs:w-[85%] xs:max-w-[1200px]">
        <div className="sm:min-h-[440px] sm:bg-right-top sm:bg-no-repeat sm:pb-[70px] sm:pt-[40px] lg:min-h-[540px]">
          <div className="flex w-full flex-col items-center sm:block">
            <Button
              className="mb-auto mt-0 w-[180px]"
              size="large"
              onClick={() => (isLogin ? navigate(Page.Trade) : navigate(Page.SignIn))}
            >
              {t('landing.act.startNow')}
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LandingBid;
