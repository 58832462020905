import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from 'src/component/Alert';
import AutoCompleteOption from 'src/component/AutoCompleteOption';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Checkbox from 'src/component/Checkbox';
import Form from 'src/component/Form';
import FormAutoComplete from 'src/component/FormAutoComplete';
import FormInput from 'src/component/FormInput';
import FormNumberInput from 'src/component/FormNumberInput';
import FormSelect from 'src/component/FormSelect';
import SelectOption from 'src/component/SelectOption';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { Ui } from 'src/constant/Env';
import { Severity } from 'src/constant/Notification';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import useQuery from 'src/hook/useQuery';
import IcCheck from 'src/image/ic-check.svg';
import { PostWithdrawalResponse, PostWithdrawalsPreviewResponse } from 'src/model/Api';
import { Contact } from 'src/model/Contact';
import { WithdrawalForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { createWithdrawal, getContactList, previewWithdrawal } from 'src/service/propertyService';
import { bn, bnFixed, bnFormat } from 'src/util/bigNumber';
import NewAddressModal from './component/NewAddressModal';
import VerifyModal from './component/VerifyModal';

type Query = {
  coinId?: string;
  network?: string;
  address?: string;
  amount?: string;
  note?: string;
};

const Withdrawal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { IcRecord, IcForward } = useContext(ThemeContext).image;
  const query = useQuery<Query>();
  const { crypto } = useSelector((rootState: RootState) => rootState.coin);
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);
  const [coinDecimal, setCoinDecimal] = useState<number>(0);
  const [contactList, setContactList] = useState<Contact[]>();
  const [isSaveAddressModalOpen, setSaveAddressModalOpen] = useState<boolean>(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState<boolean>(false);
  const methods = useForm<WithdrawalForm>();
  const formData = useWatch({ control: methods.control });
  const [withdrawalData, setWithdrawalData] = useState<PostWithdrawalResponse>();
  const [previewTask, setPreviewTask] = useState<number>();
  const [previewData, setPreviewData] = useState<PostWithdrawalsPreviewResponse>();
  const [checkboxLabel, setCheckboxLabel] = useState<string>();
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (crypto.length === 0) return;
    getContactList()
      .then((res) => setContactList(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [crypto, refresh]);

  useEffect(() => {
    if (accountInfo === undefined) return;
    if (accountInfo.otpActivated !== true) navigate(Page.Property);
  }, [accountInfo]);

  useEffect(() => {
    setPreviewData(undefined);
    if (!formData.coin || !formData.address || formData.address.length === 0) return;

    if (previewTask) window.clearTimeout(previewTask);

    const requestData = {
      coin: formData.coin,
      network: formData.network,
      address: formData.address,
    };

    const task = window.setTimeout(() => {
      previewWithdrawal(requestData)
        .then((res) => {
          setPreviewData(res);
          setPreviewTask(undefined);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
    }, 500);

    setPreviewTask(task);

    return () => window.clearTimeout(task);
  }, [formData.address]);

  useEffect(() => {
    setPreviewData(undefined);
    if (!formData.coin || !formData.address || formData.address.length === 0) return;

    previewWithdrawal({
      coin: formData.coin,
      network: formData.network,
      address: formData.address,
    })
      .then((res) => {
        setPreviewData(res);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [formData.coin, formData.network]);

  useEffect(() => {
    if (crypto.length === 0) return;

    let currentCoin = 'usdt';
    if (query.coinId && crypto.map((v) => v.id).includes(query.coinId.toLowerCase())) {
      methods.setValue('coin', query.coinId.toLowerCase());
      currentCoin = query.coinId.toLowerCase();
    } else methods.setValue('coin', 'usdt');

    const currentCrypto = crypto.find((v) => v.id === currentCoin);
    if (currentCrypto?.networks.length === 1 && currentCrypto.networks[0].network === '')
      methods.setValue('network', '');
    else if (
      query.network &&
      currentCrypto?.networks.map((v) => v.network).includes(query.network.toLowerCase())
    )
      methods.setValue('network', query.network.toLowerCase());
    else methods.setValue('network', 'trc20');

    setCoinDecimal(crypto.find((v) => v.id === currentCoin)?.decimal ?? 0);
  }, [crypto, query]);

  useEffect(() => {
    const { amount, address } = methods.getValues();

    setCheckboxLabel(
      contactList?.find((v) => v.address === address && v.coin === formData.coin)?.label,
    );

    if (previewData === undefined) return;

    if (!previewData.isAddressValid)
      methods.setError('address', { message: t('withdrawal.desc.addressInvalidate') });
    else methods.clearErrors('address');

    if (bn(amount).gt(bn(previewData.max)))
      methods.setError('amount', { message: t('withdrawal.desc.amountGreaterThan') });
    else methods.clearErrors('amount');
  }, [formData, previewData, contactList, t]);

  const onCoinChange = (coin: string) => {
    methods.setValue('coin', coin);
    if (coin === 'usdt') methods.setValue('network', 'trc20');
    else methods.setValue('network', '');
  };

  const onSubmit = (data: WithdrawalForm) => {
    createWithdrawal(
      {
        coin: data.coin,
        network: data.network,
        address: data.address,
        amount: Number.parseFloat(data.amount),
        note: data.note.length > 0 ? data.note : undefined,
      },
      data.code,
    )
      .then((res) => {
        setWithdrawalData(res);
        setVerifyModalOpen(true);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] flex flex-col items-start xs:flex-row xs:justify-between xs:justify-items-end sm:mt-[20px]">
        <H3>{t('withdrawal.heading')}</H3>
        {Ui !== 'admin' && (
          <Button
            appearance="text"
            className="mt-[20px] flex flex-row items-center xs:mt-[0px]"
            onClick={() => navigate(`${Page.Record}?type=withdrawal`)}
          >
            <img src={IcRecord} />
            {t('withdrawal.act.records')}
          </Button>
        )}
      </div>
      {crypto.length !== 0 && contactList && (
        <Form methods={methods} onSubmit={onSubmit}>
          <div className="mt-[30px] flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[20px] xs:flex-row xs:gap-[30px]">
              <div className="grow">
                <FormSelect
                  name="coin"
                  label={t('withdrawal.desc.coin')}
                  onChange={onCoinChange}
                  asterisked
                >
                  {crypto.map((v) => (
                    <SelectOption key={v.id} value={v.id}>
                      {v.id.toUpperCase()}
                    </SelectOption>
                  ))}
                </FormSelect>
              </div>
              {formData.network && (
                <div className="grow">
                  <FormSelect name="network" label={t('withdrawal.desc.selectNetwork')} asterisked>
                    {crypto
                      .find((item) => item.id === formData.coin)
                      ?.networks.map((v) => (
                        <SelectOption key={v.network} value={v.network}>
                          {v.network.toUpperCase()}
                        </SelectOption>
                      ))}
                  </FormSelect>
                </div>
              )}
            </div>
            <div className="relative xs:flex xs:flex-row">
              <div className="grow">
                <FormAutoComplete
                  name="address"
                  label={t('withdrawal.desc.address')}
                  maxLength={256}
                  placeholder={t('withdrawal.desc.recipientAddress', {
                    coin: formData.coin?.toUpperCase(),
                  })}
                  required
                  asterisked
                  defaultValue={query.address}
                >
                  {contactList
                    .filter(
                      (v) => v.coin === formData.coin && (v.network ?? '') === formData.network,
                    )
                    .map((v) => (
                      <AutoCompleteOption key={v.id} value={v.address}>
                        <div className="flex w-full gap-[10px]">
                          <div className="whitespace-nowrap">{v.label}</div>
                          <div className="truncate">{v.address}</div>
                        </div>
                      </AutoCompleteOption>
                    ))}
                </FormAutoComplete>
              </div>
              <div className="absolute right-0 top-0 xs:static xs:w-[200px]">
                {checkboxLabel !== undefined ? (
                  <div className="flex gap-[10px]">
                    <img src={IcCheck} />
                    <div className="items-center truncate">{checkboxLabel}</div>
                  </div>
                ) : (
                  <Checkbox
                    label={t('withdrawal.act.saveAddress')}
                    disabled={!previewData?.isAddressValid}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setSaveAddressModalOpen(event.currentTarget.checked)
                    }
                  />
                )}
              </div>
            </div>
            {previewData && previewData.txCount === 0 && (
              <Alert severity={Severity.Info}>
                {previewData.checksum === false
                  ? t('withdrawal.desc.newAndUnchecksummedAddressWarning')
                  : t('withdrawal.desc.newAddressWarning')}
              </Alert>
            )}
            {previewData && previewData.txCount > 0 && previewData.checksum === false && (
              <Alert severity={Severity.Info}>
                {t('withdrawal.desc.unchecksummedAddressWarning')}
              </Alert>
            )}
            <div>
              <Button
                appearance="text"
                type="button"
                className="flex flex-row items-center"
                onClick={() =>
                  navigate(
                    Ui === 'admin'
                      ? `${Page.UserAdmin}/withdrawal/management`
                      : Page.AddressManagement,
                  )
                }
              >
                {t('withdrawal.act.addressManagement')}
                <img src={IcForward} />
              </Button>
            </div>
            <div className="relative">
              <FormNumberInput
                name="amount"
                label={t('withdrawal.desc.amount')}
                placeholder={t('withdrawal.desc.receiveAmount')}
                hint={
                  previewData === undefined
                    ? `${t('withdrawal.desc.available')}: -`
                    : `${t('withdrawal.desc.available')}: ${bnFormat(
                        previewData.free,
                      )} ${previewData.coin.toUpperCase()}`
                }
                decimal={coinDecimal}
                required
                asterisked
                defaultValue={query.amount}
              />
              <Button
                appearance="text"
                className="absolute right-0 top-0"
                type="button"
                disabled={previewData === undefined}
                onClick={() => {
                  if (!previewData?.max) return;
                  methods.setValue('amount', bnFixed(previewData.max));
                }}
              >
                {t('withdrawal.act.max')}
              </Button>
            </div>
            <FormInput
              name="note"
              label={t('withdrawal.desc.memo')}
              maxLength={64}
              hint={t('withdrawal.desc.memoRule')}
              defaultValue={query.note}
            />
            <FormInput label={t('withdrawal.desc.securityCode')} name="code" required asterisked />
            <div className="text-text-secondary">
              <Body size="m">{t('withdrawal.desc.fee')}</Body>
              <Body className="text-silver-700 dark:text-white">
                {previewData === undefined
                  ? `-`
                  : `${previewData.fee} ${previewData.coin.toUpperCase()}`}
              </Body>
            </div>
          </div>
          <div className="mt-[30px] text-text-secondary">
            <Body>{t('withdrawal.desc.notice')}</Body>
            <ul>
              {t<string, string[]>('withdrawal.desc.noticeItems', {
                returnObjects: true,
                defaultValue: [],
              }).map((value, index) => (
                <li key={`notice${index}`} className="pb-[10px] text-[14px]">
                  {value}
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-[40px] text-end">
            <Button
              size="large"
              type="submit"
              disabled={
                !previewData?.isAddressValid || Object.keys(methods.formState.errors).length !== 0
              }
            >
              {t('act.submit')}
            </Button>
          </div>
        </Form>
      )}
      <NewAddressModal
        open={isSaveAddressModalOpen}
        handleClose={() => setSaveAddressModalOpen(false)}
        coin={methods.getValues('coin')}
        network={methods.getValues('network')}
        address={methods.getValues('address')}
        onFinish={() => setRefresh(!refresh)}
      />
      <VerifyModal
        open={verifyModalOpen}
        onClose={() => setVerifyModalOpen(false)}
        withdrawalData={withdrawalData}
      />
    </div>
  );
};

export default Withdrawal;
