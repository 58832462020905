import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import H3 from 'src/component/typography/H3';
import { PostAuthSigninRequest } from 'src/model/Api';
import { TwoStepVerificationForm } from 'src/model/Form';

type Props = {
  open: boolean;
  onClose: () => void;
  loginData: { email: string; password: string };
  onSignin: (data: PostAuthSigninRequest) => void;
};

const TfaModal = ({ open, onClose, loginData, onSignin }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<TwoStepVerificationForm>();

  const onSubmit = (data: TwoStepVerificationForm) => {
    onClose();
    methods.reset();
    onSignin({ ...loginData, otp: `gauth=${data.code}` });
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <div data-testid="tfa-modal">
        <H3>{t('tfaModal.heading')}</H3>
        <Form methods={methods} onSubmit={onSubmit} className="mt-[25px]">
          <FormInput label={t('tfaModal.label')} name="code" autoFocus />
          <div className="mt-[65px] flex justify-end gap-[20px]">
            <Button appearance="outlined" onClick={onClose} type="button">
              {t('act.cancel')}
            </Button>
            <Button type="submit" data-testid="tfa-btn">
              {t('act.submit')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default TfaModal;
