import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BidOrder[];
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="relative flex flex-col gap-[4px] px-[0px] py-[15px]">
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.orderId')}
          </Body>
          <Body size="m">{order.id}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.createDate')}
          </Body>
          <Body size="m">{format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.orderAmount')}
          </Body>
          <Body size="m">{`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.filledAmount')}
          </Body>
          <Body size="m">{`${bnFormat(order.filledAmount)} ${order.base.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.sentAmount')}
          </Body>
          <Body size="m">{`${bnFormat(order.sentAmount)} ${order.base.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.unitPrice')}
          </Body>
          <Body size="m">{`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.filledTotal')}
          </Body>
          <Body size="m">{`${bnFormat(order.filledTotal)} ${order.quote.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.payment')}
          </Body>
          <Body size="m">{`${bnFormat(order.sentTotal)} ${order.quote.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.filledSentTotal')}
          </Body>
          <Body size="m">{`${bn(order.filledTotal)
            .minus(order.sentTotal)
            .toFormat()} ${order.quote.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('orderHistory.desc.completeDate')}
          </Body>
          <Body size="m">
            {order.completedAt ? format(Date.parse(order.completedAt), 'yyyy/MM/dd HH:mm:ss') : '-'}
          </Body>
        </div>
        <Button
          appearance="text"
          className="absolute bottom-[15px] right-0"
          onClick={() => navigate(`${Page.Order}/${order.id}`)}
        >
          {t('orderHistory.act.detail')}
        </Button>
      </div>
      <Divider theme="sub" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
