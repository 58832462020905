import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BidOrder[];
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="flex flex-row">
        <Body
          size="m"
          className="box-border flex w-1/12 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px]"
        >
          {order.id}
        </Body>
        <Body
          size="m"
          className="box-border flex w-1/6 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px]"
        >
          {format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </Body>
        <div className="box-border flex w-1/4 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px]">
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('orderHistory.desc.amount')}
            </Body>
            <Body size="m">{`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}</Body>
          </div>
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('orderHistory.desc.filledAmount')}
            </Body>
            <Body size="m">{`${bnFormat(order.filledAmount)} ${order.base.toUpperCase()}`}</Body>
          </div>
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('orderHistory.desc.sentAmount')}
            </Body>
            <Body size="m">{`${bnFormat(order.sentAmount)} ${order.base.toUpperCase()}`}</Body>
          </div>
        </div>
        <Body
          size="m"
          className="box-border flex w-1/12 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px]"
        >
          {`${order.price} ${order.quote.toUpperCase()}`}
        </Body>
        <div className="box-border flex w-1/6 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px]">
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('orderHistory.desc.filledTotal')}
            </Body>
            <Body size="m">{`${bnFormat(order.filledTotal)} ${order.quote.toUpperCase()}`}</Body>
          </div>
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('orderHistory.desc.payment')}
            </Body>
            <Body size="m">{`${bnFormat(order.sentTotal)} ${order.quote.toUpperCase()}`}</Body>
          </div>
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('orderHistory.desc.filledSentTotal')}
            </Body>
            <Body size="m">{`${bn(order.filledTotal)
              .minus(order.sentTotal)
              .toFormat()} ${order.quote.toUpperCase()}`}</Body>
          </div>
        </div>
        <Body
          size="m"
          className="box-border flex w-1/6 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px]"
        >
          {order.completedAt ? format(Date.parse(order.completedAt), 'yyyy/MM/dd HH:mm:ss') : '-'}
        </Body>
        <div className="box-border flex w-1/12 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px]">
          <Button appearance="text" onClick={() => navigate(`${Page.Order}/${order.id}`)}>
            {t('orderHistory.act.detail')}
          </Button>
        </div>
      </div>
      <Divider theme="sub" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <Body
          size="m"
          bold
          className="box-border flex w-1/12 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('orderHistory.desc.orderId')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/6 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('orderHistory.desc.createDate')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/4 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {`${t('orderHistory.desc.orderAmount')}`}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/12 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('orderHistory.desc.unitPrice')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/6 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {`${t('orderHistory.desc.total')}`}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/6 flex-col gap-[8px] break-words py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('orderHistory.desc.completeDate')}
        </Body>
      </div>
      <Divider theme="sub" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default Table;
