import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { openSnackbar } from 'src/redux/uiSlice';
import { getWithdrawalWithId } from 'src/service/propertyService';
import { bnFormat } from 'src/util/bigNumber';

const WithdrawalDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { IcCopy } = useContext(ThemeContext).image;
  const { recordId } = useParams();
  const [record, setRecord] = useState<WithdrawalRecord>();

  useEffect(() => {
    if (recordId === undefined) {
      navigate(Page.Record, { replace: true });

      return;
    }

    getWithdrawalWithId(recordId)
      .then((res) => {
        setRecord(res);
      })
      .catch(() => {
        navigate(Page.Record, { replace: true });
      });
  }, [recordId]);

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  return (
    <div>
      <BackButton />
      <H3 className="mb-[30px] mt-[10px] sm:mt-[20px]">
        {t('recordDetail.heading', { type: t('recordDetail.desc.withdrawal') })}
      </H3>
      {record && (
        <>
          <Body bold>{`${bnFormat(record.amount)} ${record.coin.toUpperCase()}`}</Body>
          <div className="mt-[20px] grid auto-rows-fr grid-cols-[1fr] xs:grid-cols-[1fr_1fr] sm:grid-cols-[1fr_1fr_1fr_1fr]">
            <div className="py-[20px]">
              <Body className="text-text-secondary">{t('recordDetail.desc.type')}</Body>
              <Body>
                {record.isInternal
                  ? t('recordDetail.desc.internalTransfer')
                  : t('recordDetail.desc.withdrawal')}
              </Body>
            </div>
            <div className="py-[20px]">
              <Body className="text-text-secondary">{t('recordDetail.desc.status')}</Body>
              <Body>{t(`recordDetail.desc.statusDisplay.${record.status}`)}</Body>
            </div>
            {record.note && (
              <div className="py-[20px]">
                <Body className="text-text-secondary">{t('recordDetail.desc.memo')}</Body>
                <Body>{record.note}</Body>
              </div>
            )}
            <div className="py-[20px]">
              <Body className="text-text-secondary">{t('recordDetail.desc.time')}</Body>
              <Body>{format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}</Body>
            </div>
          </div>
          <Divider theme="sub" className="my-[10px]" />
          <div>
            <div className="py-[20px]">
              <div className="flex flex-row items-center justify-between text-text-secondary xs:justify-start">
                <Body>{t('recordDetail.desc.address')}</Body>
                <CopyToClipboard onCopy={onCopy} text={record.address}>
                  <img className="ml-[10px] cursor-pointer" src={IcCopy} />
                </CopyToClipboard>
              </div>
              <Body size="m">{record.address}</Body>
            </div>
            {record.transactionHash && (
              <div className="py-[20px]">
                <div className="flex flex-row items-center justify-between text-text-secondary xs:justify-start">
                  <Body>{t('recordDetail.desc.txId')}</Body>
                  <CopyToClipboard onCopy={onCopy} text={record.transactionHash}>
                    <img className="ml-[10px] cursor-pointer" src={IcCopy} />
                  </CopyToClipboard>
                </div>
                <Body size="m">{record.transactionHash}</Body>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default WithdrawalDetail;
