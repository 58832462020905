import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Body from 'src/component/typography/Body';
import H4 from 'src/component/typography/H4';
import { Ui } from 'src/constant/Env';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';

const WithdrawalSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { IcSuccess } = useContext(ThemeContext).image;
  const state = location.state as { userId: string } | null;

  useEffect(() => {
    if (!state?.userId)
      navigate(Ui === 'admin' ? Page.UserAdmin : Page.Withdrawal, { replace: true });
  }, []);

  return (
    <div>
      <BackButton />
      <div className="mx-0 my-[40px] flex items-center justify-center sm:mx-0 sm:mb-[40px] sm:mt-[70px]">
        <img className="w-[72px]" src={IcSuccess} />
      </div>
      <H4 className="mb-[10px] text-center">{t('withdrawal.desc.successHead')}</H4>
      <Body size="m" className="mb-[40px] text-center">
        {t('withdrawal.desc.successText')}
      </Body>
      <div className="text-center">
        <Button
          className="px-[40px] py-[13px]"
          size="large"
          onClick={() => {
            if (Ui === 'admin' && state?.userId !== 'xx')
              navigate(Page.WithdrawalRecord, { state: { userId: state?.userId } });
            else if (Ui === 'admin' && state?.userId === 'xx') navigate(Page.WithdrawalRecord);
            else navigate(`${Page.Record}?type=withdrawal`, { replace: true });
          }}
        >
          {t('withdrawal.act.gotoRecord')}
        </Button>
      </div>
    </div>
  );
};

export default WithdrawalSuccess;
