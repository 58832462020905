import classNames from 'classnames';
import { isValidElement, ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Body from 'src/component/typography/Body';
import SelectContext from 'src/context/SelectContext';
import IcArrowDown from 'src/image/ic-arrow-down.svg';
import Popover from './Popover';

/**
 * usage example:
 *
 * <Select>
 *   <SelectOption value="aa">a</SelectOption>
 *   <SelectOption value="bb">b</SelectOption>
 *   <SelectOption value="cc">c</SelectOption>
 * </Select>
 */

export type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (v: string) => void;
  label?: string;
  hint?: string;
  error?: boolean | string;
  asterisked?: boolean;
};

const Select = ({
  children,
  className,
  defaultValue,
  value: controlledSelectedValue,
  onChange,
  label,
  hint,
  error,
  asterisked = false,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; children: ReactNode }[]>([]);
  const [selected, setSelected] = useState<string>(defaultValue ?? '');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOptions(
      (Array.isArray(children) ? children : [children]).map((child) =>
        isValidElement(child) ? child.props : null,
      ),
    );
  }, [children]);

  const handleChange = (v: string) => {
    setOpen(false);
    if (controlledSelectedValue === undefined) setSelected(v);

    onChange && onChange(v);
  };

  return (
    <SelectContext.Provider value={{ current: controlledSelectedValue ?? selected, handleChange }}>
      <div>
        {label && (
          <Body size="m" className="text-text-input-subtle">
            {label}
            {asterisked && <span className="text-text-alert">*</span>}
          </Body>
        )}
        <div
          className={twMerge(
            `flex h-[40px] w-full cursor-pointer items-center justify-between border-0
            border-b border-solid border-border-input-normal text-text-body`,
            classNames({
              'border-solid border-0 border-b border-border-input-error': !!error,
            }),
            className,
          )}
          onClick={() => setOpen(!open)}
          ref={ref}
        >
          <div>
            {options.find((v) => v.value === (controlledSelectedValue ?? selected))?.children}
          </div>
          <img src={IcArrowDown} />
        </div>
        {typeof error === 'string' && (
          <Body size="s" className="mt-[5px] text-text-alert">
            {error}
          </Body>
        )}
        {hint && (
          <Body size="s" className="mt-[5px] text-text-input-helper">
            {hint}
          </Body>
        )}
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        cssProperties={{ width: ref.current?.offsetWidth }}
      >
        {children}
      </Popover>
    </SelectContext.Provider>
  );
};

export default Select;
