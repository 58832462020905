import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Alert from 'src/component/Alert';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import H3 from 'src/component/typography/H3';
import { Severity } from 'src/constant/Notification';
import { TwoStepVerificationForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { deactivateOtp } from 'src/service/authService';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const DisableTfaModal = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<TwoStepVerificationForm>();

  const onSubmit = (data: TwoStepVerificationForm) => {
    handleClose();
    methods.reset();
    deactivateOtp('gauth', data.code)
      .then(() => dispatch(openSnackbar({ message: t('account.desc.deactivateSuccessfully') })))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form methods={methods} onSubmit={onSubmit} className="flex flex-col">
        <H3>{t('account.desc.disableTfa')}</H3>
        <div className="mt-[25px]">
          <FormInput name="code" label={t('account.desc.certificationCode')} autoFocus />
        </div>
        <Alert severity={Severity.Info} className="mt-[25px]">
          {t('account.desc.doNotHaveCode')}
        </Alert>
        <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" type="button" onClick={handleClose}>
            {t('account.act.cancel')}
          </Button>
          <Button type="submit" className="px-[30px] py-[9px]">
            {t('account.act.confirmDisable')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DisableTfaModal;
