import classNames from 'classnames';
import { ChangeEvent, forwardRef, InputHTMLAttributes, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Body from './typography/Body';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  hint?: string;
  error?: boolean | string;
  asterisked?: boolean;
  regex?: RegExp;
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      hint,
      error,
      asterisked = false,
      className,
      disabled,
      autoComplete = 'off',
      onChange,
      regex,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState<string>();
    const onInput = (v: ChangeEvent<HTMLInputElement>) => {
      if (regex !== undefined && regex.test(v.target.value) === false) return;
      setValue(v.target.value);
      onChange && onChange(v);
    };

    return (
      <div>
        {label && (
          <Body
            size="m"
            className={classNames({
              'text-text-input-subtle': !disabled,
              'text-text-input-subtle-disabled': disabled,
            })}
          >
            {label}
            {asterisked && <span className="text-text-alert">*</span>}
          </Body>
        )}
        <input
          className={twMerge(
            `box-border h-[40px] w-full border-0 
            border-b border-solid border-b-border-input-normal 
            bg-transparent px-0 py-[8px] font-[inherit] text-[16px] text-text-body
            placeholder:text-text-input-placeholder focus:border-b-border-input-focus focus:outline-none`,
            classNames({
              'border-b-border-input-error': !!error,
              'border-b-border-input-disabled text-text-input-disabled': disabled,
            }),
            className,
          )}
          disabled={disabled}
          autoComplete={autoComplete}
          ref={ref}
          value={value}
          onChange={onInput}
          {...props}
        />
        {(typeof error === 'string' || hint) && (
          <div className="mt-[5px]">
            {typeof error === 'string' && (
              <Body size="s" className="text-text-alert">
                {error}
              </Body>
            )}
            {hint && (
              <Body
                size="s"
                className={classNames('whitespace-pre-wrap', {
                  'text-text-input-helper': !disabled,
                  'text-text-input-helper-disabled': disabled,
                })}
              >
                {hint}
              </Body>
            )}
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
