import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * usage example:
 *
 * <Switcher
 *   labels={['item1', 'item2']}
 *   onChange={(i) => console.log(i)}
 *   defaultIndex={1}
 * />
 */

type Props = {
  labels: [string, string];
  onChange?: (i: number) => void;
  defaultIndex?: 0 | 1;
};

const Switcher = ({ onChange, labels, defaultIndex = 0 }: Props) => {
  const [current, setCurrent] = useState<number>(defaultIndex);
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const { ready } = useTranslation();

  useEffect(() => {
    setWidth(ref.current?.children[current].getBoundingClientRect().width ?? 0);

    const parentLeft = ref.current?.getBoundingClientRect().left ?? 0;
    const childLeft = ref.current?.children[current].getBoundingClientRect().left ?? 0;
    setOffset(childLeft - parentLeft);
  }, [ref, current, ready]);

  const onClick = (i: number) => () => {
    setCurrent(i);
    onChange && onChange(i);
  };

  return (
    <div className="relative flex w-fit rounded-[4px] bg-bg-segment-normal" ref={ref}>
      {labels.map((label, i) => (
        <div
          key={i}
          onClick={onClick(i)}
          className={classNames(
            'z-[10] box-border flex h-[40px] min-w-[116px] cursor-pointer items-center justify-center px-[21px] py-[8px]',
            {
              'text-text-segment-focus': i === current,
              'text-text-segment-normal': i !== current,
            },
          )}
        >
          {label}
        </div>
      ))}
      <div
        style={{ width, left: offset }}
        className={classNames(
          'absolute h-[40px] border-border-segment-divider bg-bg-segment-focus from-[-38%] to-[61%] transition-[left_width] duration-200',
          {
            'border-solid border-0 border-r rounded-tl-[4px] rounded-bl-[4px]': current === 0,
            'border-solid border-0 border-l rounded-tr-[4px] rounded-br-[4px]': current === 1,
          },
        )}
      />
    </div>
  );
};

export default Switcher;
