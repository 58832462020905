import { Modal as MuiModal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import IcNext from 'src/image/ic-arrow-next.svg';
import IcPrev from 'src/image/ic-arrow-prev.svg';
import IcClose from 'src/image/ic-close.svg';
import { RootState } from 'src/redux/store';
import Button from './Button';

const PinnedNotice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pinnedAnnouncement } = useSelector((rootState: RootState) => rootState.ui);
  const [idx, setIdx] = useState<number>();

  useEffect(() => {
    if (pinnedAnnouncement.length > 0) setIdx(0);
  }, [pinnedAnnouncement]);

  const onView = () => {
    navigate(`${Page.Announcement}/${pinnedAnnouncement[idx ?? 0].id}`);
    setIdx(undefined);
  };

  return (
    <MuiModal
      open={pinnedAnnouncement.length > 0 && idx !== undefined}
      onClose={() => setIdx(undefined)}
    >
      <div className="relative left-[50%] top-[50%] max-h-[calc(100vh-140px)] w-[calc(100vw-30px)] translate-x-[-50%] translate-y-[-50%] outline-none xs:w-[480px]">
        <div className="h-[12px] rounded-t-[12px] bg-bg-stress-warning" />
        {idx !== undefined && (
          <div className="relative box-border rounded-b-[12px] bg-bg-modal p-[30px]">
            <img
              src={IcClose}
              className="absolute right-[10px] top-[10px] cursor-pointer"
              onClick={() => setIdx(undefined)}
            />
            <div className="text-[32px] font-bold leading-[41.6px]">{t('pinnedNotice.head')}</div>
            <div className="mx-0 mb-[40px] mt-[20px] max-h-[calc(100vh-140px-60px)] overflow-y-auto whitespace-pre-line break-words">
              {pinnedAnnouncement[idx].subject}
            </div>
            <div className="text-right">
              <Button className="font-bold" onClick={onView}>
                {t('pinnedNotice.view')}
              </Button>
            </div>
          </div>
        )}
        {idx !== undefined && idx > 0 && (
          <div
            className="absolute left-0 top-[-45px] flex cursor-pointer items-center font-bold leading-[1.5] text-text-white"
            onClick={() => setIdx(idx - 1)}
          >
            <img src={IcPrev} />
            <div>{t('pinnedNotice.prev')}</div>
          </div>
        )}
        {idx !== undefined && idx < pinnedAnnouncement.length - 1 && (
          <div
            className="absolute right-0 top-[-45px] flex cursor-pointer items-center font-bold leading-[1.5] text-text-white"
            onClick={() => setIdx(idx + 1)}
          >
            <div>{t('pinnedNotice.next')}</div>
            <img src={IcNext} />
          </div>
        )}
      </div>
    </MuiModal>
  );
};

export default PinnedNotice;
