import classNames from 'classnames';
import { HTMLAttributes, useContext } from 'react';
import { twMerge } from 'tailwind-merge';
import { Severity } from 'src/constant/Notification';
import { ThemeContext } from 'src/context/ThemeContext';
import Body from './typography/Body';

type Props = HTMLAttributes<HTMLDivElement> & {
  severity: Severity;
};

const Alert = ({ severity, className, children, ...props }: Props) => {
  const { IcNoticeClear, IcNoticeWarning, IcNoticeError } = useContext(ThemeContext).image;
  const icons = {
    [Severity.Info]: IcNoticeClear,
    [Severity.Warning]: IcNoticeWarning,
    [Severity.Error]: IcNoticeError,
  };

  return (
    <div
      className={twMerge(
        className,
        'flex flex-row rounded-[4px] p-[10px]',
        classNames({
          'bg-bg-accent-info': severity === Severity.Info,
          'bg-bg-accent-warning': severity === Severity.Warning,
          'bg-bg-accent-alert': severity === Severity.Error,
        }),
      )}
      {...props}
    >
      <img className="mr-[10px] h-[21px] w-[21px]" src={icons[severity]} />
      <Body size="m">{children}</Body>
    </div>
  );
};

export default Alert;
