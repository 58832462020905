import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = HTMLAttributes<HTMLDivElement> & {
  theme?: 'accent' | 'sub';
};

const Divider = ({ className, theme = 'accent', ...props }: Props) => (
  <div
    className={twMerge(
      classNames('h-[1px]', {
        'bg-border-divider-accent': theme === 'accent',
        'bg-border-divider-sub': theme === 'sub',
      }),
      className,
    )}
    {...props}
  />
);

export default Divider;
