import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';

/**
 * usage example:
 *
 * const [state, setState] = useState({
 *   gilad: true,
 *   jason: false,
 *   antoine: false,
 * });
 * const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
 *   setState({ ...state, [event.target.name]: event.target.checked });
 * };
 * const { gilad, jason, antoine } = state;
 *
 *
 * <Checkbox checked={gilad} onChange={handleChange} name="gilad" label="Gilad" />
 * <Checkbox checked={jason} onChange={handleChange} name="jason" label="Jason" />
 * <Checkbox checked={antoine} onChange={handleChange} name="antoine" label="Antoine" />
 */

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  asterisked?: boolean;
};

const Checkbox = ({ label, asterisked, disabled, ...props }: Props) => (
  <div className="flex w-fit items-center">
    <input
      className={`relative m-0 cursor-pointer appearance-none rounded-[2px] border-[2px] border-solid border-border-checkbox-normal bg-transparent p-[7px]
        after:absolute after:left-[4px] after:top-[-1px] after:hidden after:h-[12px] after:w-[5px] after:rotate-45
        after:border-b-2 after:border-l-0 after:border-r-2 after:border-t-0 after:border-solid after:border-border-checkbox-check
        checked:border-bg-checkbox-normal checked:bg-bg-checkbox-normal checked:content-[''] checked:after:block
        disabled:border-border-checkbox-disabled disabled:after:border-border-checkbox-disabled disabled:checked:border-bg-checkbox-disabled
        disabled:checked:bg-bg-checkbox-disabled dark:disabled:border-border-checkbox-disabled`}
      id={label}
      disabled={disabled}
      {...props}
      type="checkbox"
    />
    {label && (
      <label
        htmlFor={label}
        className={classNames('cursor-pointer pl-[10px] text-[14px]', {
          'text-text-button-disabled': disabled,
        })}
      >
        {label}
        {asterisked && <span className="text-text-alert">*</span>}
      </label>
    )}
  </div>
);

export default Checkbox;
