import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetConfigsResponse, GetOrderBidPriceResponse } from 'src/model/Api';
import { Crypto } from 'src/model/Crypto';
import { ExchangeRate } from 'src/model/ExchangeRate';
import { Rebate } from 'src/model/Rebate';

export type SettingState = {
  config: GetConfigsResponse | null;
  exchangeRate: ExchangeRate[] | null;
  bidPrice: GetOrderBidPriceResponse[] | null;
  agentRebate: Rebate[];
  feeCrypto: Crypto[] | null;
};

const initialState: SettingState = {
  config: null,
  exchangeRate: null,
  bidPrice: null,
  agentRebate: [],
  feeCrypto: null,
};

export const settingSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state: SettingState, action: PayloadAction<GetConfigsResponse>) => {
      state.config = action.payload;
    },
    setExchangeRate: (state: SettingState, action: PayloadAction<ExchangeRate[]>) => {
      state.exchangeRate = action.payload;
    },
    setBidPrice: (state: SettingState, action: PayloadAction<GetOrderBidPriceResponse[]>) => {
      state.bidPrice = action.payload;
    },
    setAgentRebate: (state: SettingState, action: PayloadAction<Rebate[]>) => {
      state.agentRebate = action.payload;
    },
    setFeeCrypto: (state: SettingState, action: PayloadAction<Crypto[]>) => {
      state.feeCrypto = action.payload;
    },
  },
});

export const { setConfig, setExchangeRate, setBidPrice, setAgentRebate, setFeeCrypto } =
  settingSlice.actions;

export default settingSlice.reducer;
