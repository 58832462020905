import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { Ui } from 'src/constant/Env';
import { Page } from 'src/constant/Page';
import { PostAuthSigninRequest } from 'src/model/Api';
import { LoginForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { signin } from 'src/service/authService';
import TfaModal from './component/TfaModal';

const AuthSignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm<LoginForm>();
  const [open, setOpen] = useState<boolean>(false);
  const redirectPath = location.state as { from: string } | undefined;

  const onSignin = (data: PostAuthSigninRequest) => {
    signin(data)
      .then((res) => {
        if (res === 'no-kyc') navigate(Page.KycVerification);
        else if (res === 'no-otp') navigate(Page.EnableTfa);
        else if (redirectPath?.from) navigate(redirectPath.from);
        else navigate(Ui === 'admin' ? Page.Home : Page.Trade);
      })
      .catch((e) => {
        if (e === 'unverified')
          navigate(Page.Verification, { state: { email: data.email, password: data.password } });
        else if (e === 'otp') setOpen(true);
        else dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  };

  const onSubmit = (data: LoginForm) => onSignin(data);

  return (
    <>
      <Form
        className="px-[15px] pb-10 pt-[10px] xs:mx-auto xs:w-[560px] xs:px-0 xs:pb-[70px] md:w-[640px]"
        methods={methods}
        onSubmit={onSubmit}
      >
        <H3>{t('login.heading')}</H3>
        {Ui === 'seller' && (
          <Body size="m" className="mt-[16px] flex items-center gap-2">
            {t('login.desc.noAccount')}
            <Button
              appearance="text"
              onClick={() => navigate(Page.Register)}
              type="button"
              data-testid="register-btn"
            >
              {t('login.act.register')}
            </Button>
          </Body>
        )}
        <div className="mt-[25px]">
          <FormInput
            name="email"
            type="email"
            data-testid="email-input"
            label={t('login.desc.account')}
            placeholder={t('login.desc.accountPlaceholder')}
            required
            autoFocus
          />
        </div>
        <div className="mt-[25px]">
          <FormInput
            name="password"
            type="password"
            data-testid="pwd-input"
            label={t('login.desc.password')}
            required
          />
        </div>
        <div className="mt-5">
          <Button
            appearance="text"
            onClick={() => navigate(Page.ForgetPassword)}
            type="button"
            data-testid="forget-btn"
          >
            {t('login.act.forgotPassword')}
          </Button>
        </div>
        <div className="mt-[30px] text-right">
          <Button type="submit" data-testid="submit-btn">
            {t('login.act.signIn')}
          </Button>
        </div>
      </Form>
      <TfaModal
        open={open}
        onClose={() => setOpen(false)}
        loginData={methods.getValues()}
        onSignin={onSignin}
      />
    </>
  );
};

export default AuthSignIn;
