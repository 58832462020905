import { Popover as MuiPopover, PopoverProps } from '@mui/material';
import { CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';

export type Props = PopoverProps & {
  onClose: () => void;
  cssProperties?: CSSProperties;
};

const Popover = ({
  open,
  anchorEl,
  onClose,
  children,
  className,
  cssProperties,
  ...props
}: Props) => (
  <MuiPopover
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    slotProps={{
      paper: {
        style: cssProperties,
        className: twMerge('rounded-xl !bg-bg-surface-overlay', className),
      },
    }}
    {...props}
  >
    {children}
  </MuiPopover>
);

export default Popover;
