import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Ui } from 'src/constant/Env';
import { PageStrictlyInDark } from 'src/constant/Page';
import { Image, Theme } from 'src/constant/Theme';

/**
 * usage example:
 *
 * const { toggleTheme } = useContext(ThemeContext);
 * <Button onClick={toggleTheme}>switch</Button>
 */

type ThemeContextType = {
  theme: Theme;
  toggleTheme: () => void;
  image: { [key: string]: string };
};
export const ThemeContext = createContext<ThemeContextType>({
  theme: Theme.YellowLight,
  toggleTheme: () => undefined,
  image: {},
});

type ThemeProviderProps = {
  children: ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(Theme.YellowLight);
  const location = useLocation();
  const strictlyInDark = useMemo(
    () => PageStrictlyInDark.find((v) => v === location.pathname) !== undefined,
    [location.pathname],
  );

  const getCurrentTheme = useCallback(() => {
    const res = localStorage.getItem('theme');
    if (strictlyInDark) return Ui === 'buyer' ? Theme.BlueDark : Theme.YellowDark;
    if (Ui === 'admin') return Theme.YellowLight;
    if (Ui === 'seller')
      return res === Theme.YellowDark || res === Theme.YellowLight ? res : Theme.YellowLight;

    return res === Theme.BlueDark || res === Theme.BlueLight ? res : Theme.BlueLight;
  }, [strictlyInDark]);

  const getNextTheme = useCallback(() => {
    if (strictlyInDark) return Ui === 'buyer' ? Theme.BlueDark : Theme.YellowDark;
    if (Ui === 'admin') return Theme.YellowLight;
    if (Ui === 'seller') return theme === Theme.YellowLight ? Theme.YellowDark : Theme.YellowLight;

    return theme === Theme.BlueLight ? Theme.BlueDark : Theme.BlueLight;
  }, [theme, strictlyInDark]);

  useEffect(() => {
    const current = getCurrentTheme();
    document.documentElement.classList.remove(...document.documentElement.classList);
    document.documentElement.classList.toggle(current);
    setTheme(current);
  }, [strictlyInDark]);

  const toggleTheme = () => {
    const current = getCurrentTheme();
    const next = getNextTheme();
    localStorage.setItem('theme', next);
    document.documentElement.classList.toggle(current);
    document.documentElement.classList.toggle(next);
    setTheme(next);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, image: Image[theme] }}>
      {children}
    </ThemeContext.Provider>
  );
};
