import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RebateInfo } from 'src/model/Rebate';

export type RebateState = {
  me: RebateInfo | null;
  kids: RebateInfo[] | null;
  members: RebateInfo[] | null;
};

const initialState: RebateState = {
  me: null,
  kids: null,
  members: null,
};

export const rebateSlice = createSlice({
  name: 'rebate',
  initialState,
  reducers: {
    setRebateStatementMe: (state: RebateState, action: PayloadAction<RebateInfo>) => {
      state.me = action.payload;
    },
    setRebateStatementKids: (state: RebateState, action: PayloadAction<RebateInfo[]>) => {
      state.kids = action.payload;
    },
    setRebateStatementMembers: (state: RebateState, action: PayloadAction<RebateInfo[]>) => {
      state.members = action.payload;
    },
    resetRebate: (state: RebateState) => {
      state.me = null;
      state.kids = null;
      state.members = null;
    },
  },
});

export const {
  setRebateStatementMe,
  setRebateStatementKids,
  setRebateStatementMembers,
  resetRebate,
} = rebateSlice.actions;

export default rebateSlice.reducer;
