import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Pagination from 'src/component/Pagination';
import DateFilter from 'src/component/RoundDateFilter';
import RoundSelect from 'src/component/RoundSelect';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import H3 from 'src/component/typography/H3';
import { MediaQuery } from 'src/constant/Media';
import { ThemeContext } from 'src/context/ThemeContext';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import { BidOrder } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getBidOrdersHistory } from 'src/service/orderService';
import Card from './component/Card';
import Table from './component/Table';

const OrderBidHistory = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const { orderHistory: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const { IcEmpty } = useContext(ThemeContext).image;
  const { offset, limit, setCount, paginationProps } = usePagination({ defaultPage: filter.page });
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const [filterStatus, setFilterStatus] = useState<string>(filter.status ?? 'all');
  const [filterSorting, setFilterSorting] = useState<string>(filter.sorting ?? 'default');
  const [orderArray, setOrderArray] = useState<Array<BidOrder>>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);

  useEffect(() => {
    getBidOrdersHistory(filterStatus, filterSorting, { begin, end, offset, limit })
      .then((res) => {
        setOrderArray(res.data);
        setCount(res.count);
      })
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [refresh, filterStatus, filterSorting, begin, end, offset, limit]);

  const onStatusFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterStatus(value);
  };

  const onSortingFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterSorting(value);
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <H3 className="mb-[15px] mt-[10px] sm:mt-[20px]">{t('orderHistory.heading')}</H3>
      <div className="text-right">
        <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
          {t('orderHistory.act.refresh')}
        </Button>
      </div>
      {orderArray && (
        <>
          <div className="mt-[15px] rounded-[12px] bg-bg-content px-[15px] pb-[10px] pt-[25px] xs:px-[40px] xs:pb-[15px] xs:pt-[25px]">
            <div className="flex flex-col flex-wrap justify-between gap-x-[15px] xs:flex-row">
              <div className="flex flex-col xs:flex xs:flex-row xs:gap-[15px]">
                <DateFilter onChange={() => paginationProps.setPage(1)} {...dateFilterProps} />
                <div className="mb-[15px] w-fit">
                  <RoundSelect
                    label={t('orderHistory.desc.status')}
                    defaultValue={filter.status ?? 'all'}
                    onChange={onStatusFilterChange}
                  >
                    <SelectOption value="all">
                      {t('orderHistory.desc.statusOption.all')}
                    </SelectOption>
                    <SelectOption value="open,executed">
                      {t('orderHistory.desc.statusOption.executed')}
                    </SelectOption>
                    <SelectOption value="canceled">
                      {t('orderHistory.desc.statusOption.canceled')}
                    </SelectOption>
                    <SelectOption value="completed">
                      {t('orderHistory.desc.statusOption.completed')}
                    </SelectOption>
                  </RoundSelect>
                </div>
              </div>
              <div className="mb-[15px]">
                <SortingSelect
                  defaultValue={filter.sorting ?? 'default'}
                  onChange={onSortingFilterChange}
                >
                  <SelectOption value="default">
                    {t('orderHistory.desc.sortingOption.default')}
                  </SelectOption>
                  <SelectOption value="-price">
                    {t('orderHistory.desc.sortingOption.priceDesc')}
                  </SelectOption>
                  <SelectOption value="price">
                    {t('orderHistory.desc.sortingOption.priceAsce')}
                  </SelectOption>
                  <SelectOption value="-sent_amount">
                    {t('orderHistory.desc.sortingOption.sentAmountDesc')}
                  </SelectOption>
                  <SelectOption value="sent_amount">
                    {t('orderHistory.desc.sortingOption.sentAmountAsce')}
                  </SelectOption>
                  <SelectOption value="-sent_total">
                    {t('orderHistory.desc.sortingOption.sentTotalDesc')}
                  </SelectOption>
                  <SelectOption value="sent_total">
                    {t('orderHistory.desc.sortingOption.sentTotalAsce')}
                  </SelectOption>
                  <SelectOption value="-filled_amount">
                    {t('orderHistory.desc.sortingOption.filledAmountDesc')}
                  </SelectOption>
                  <SelectOption value="filled_amount">
                    {t('orderHistory.desc.sortingOption.filledAmountAsce')}
                  </SelectOption>
                  <SelectOption value="-filled_total">
                    {t('orderHistory.desc.sortingOption.filledTotalDesc')}
                  </SelectOption>
                  <SelectOption value="filled_total">
                    {t('orderHistory.desc.sortingOption.filledTotalAsce')}
                  </SelectOption>
                </SortingSelect>
              </div>
            </div>
            <Divider theme="sub" />
            {orderArray.length === 0 && (
              <div className="text-center">
                <img src={IcEmpty} />
              </div>
            )}
            {orderArray.length > 0 &&
              (isBiggerThanMd ? <Table data={orderArray} /> : <Card data={orderArray} />)}
          </div>
          <div className="mt-[15px] flex flex-row-reverse">
            <Pagination {...paginationProps} />
          </div>
        </>
      )}
    </div>
  );
};

export default OrderBidHistory;
