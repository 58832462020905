import balanceEndpoint from 'src/api/balanceEndpoint';
import balanceTransactionEndpoint from 'src/api/balanceTransactionEndpoint';
import depositEndpoint from 'src/api/depositEndpoint';
import fillsEndpoint from 'src/api/fillsEndpoint';
import withdrawalContactEndpoint from 'src/api/withdrawalContactEndpoint';
import withdrawalEndpoint from 'src/api/withdrawalEndpoint';
import {
  GetBalanceTransactionsParams,
  GetDepositAddressParams,
  GetDepositsParams,
  GetWithdrawalsParams,
  PostWithdrawalContactRequest,
  PostWithdrawalRequest,
  PostWithdrawalsPreviewRequest,
} from 'src/model/Api';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';

export const getBalance = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await balanceEndpoint.getBalance(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getAddress = async (params: GetDepositAddressParams) => {
  try {
    dispatch(startWaiting());
    const res = await depositEndpoint.getAddress(params);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getDeposits = async (params: GetDepositsParams) => {
  try {
    dispatch(startWaiting());
    const res = await depositEndpoint.getDeposits({ ...params, order: 'desc' });

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getDepositsWithId = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await depositEndpoint.getDepositsWithId(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getContactList = async () => {
  try {
    dispatch(startWaiting());
    const res = await withdrawalContactEndpoint.getWithdrawalContactList();

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const createContact = async (data: PostWithdrawalContactRequest) => {
  try {
    dispatch(startWaiting());
    await withdrawalContactEndpoint.createWithdrawalContact(data);
  } finally {
    dispatch(finishWaiting());
  }
};

export const deleteContact = async (id: string) => {
  try {
    dispatch(startWaiting());
    await withdrawalContactEndpoint.deleteWithdrawalContact(id);
  } finally {
    dispatch(finishWaiting());
  }
};

export const previewWithdrawal = async (data: PostWithdrawalsPreviewRequest) => {
  const res = await withdrawalEndpoint.postWithdrawalPreview(data);

  return res.data;
};

export const createWithdrawal = async (
  data: Omit<PostWithdrawalRequest, 'clientId'>,
  code: string,
) => {
  try {
    dispatch(startWaiting());
    const res = await withdrawalEndpoint.postWithdrawal(
      {
        ...data,
        clientId: `${Date.now()}`,
      },
      code,
    );

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const verifyWithdrawal = async (id: string, code: string) => {
  try {
    dispatch(startWaiting());
    await withdrawalEndpoint.patchWithdrawalVerify(id, { code });
  } finally {
    dispatch(finishWaiting());
  }
};

export const getWithdrawals = async (params: GetWithdrawalsParams) => {
  try {
    dispatch(startWaiting());
    const res = await withdrawalEndpoint.getWithdrawals({ ...params, order: 'desc' });

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getWithdrawalWithId = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await withdrawalEndpoint.getWithdrawalsWithId(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getRecordData = async (
  transactionType: 'deposit' | 'withdrawal',
  coin?: string,
  paginationParams?: PaginationParams,
) => {
  dispatch(
    updateFilter({
      key: 'record',
      filter: {
        type: transactionType,
        coin,
        begin: paginationParams?.begin,
        end: paginationParams?.end,
        page: getPage(paginationParams?.offset, paginationParams?.limit),
      },
    }),
  );

  if (transactionType === 'deposit') {
    const res = await getDeposits({ coin, ...paginationParams });

    return {
      transactionType,
      ...res,
    };
  } else {
    const res = await getWithdrawals({ coin, ...paginationParams });

    return {
      transactionType,
      ...res,
    };
  }
};

export const getBalanceTransactions = async (
  type: string,
  params?: GetBalanceTransactionsParams,
) => {
  try {
    dispatch(startWaiting());
    const res = await balanceTransactionEndpoint.getBalanceTransaction({
      ...params,
      coin: 'usdt',
      type: type === 'all' ? undefined : type,
      order: 'desc',
    });

    dispatch(
      updateFilter({
        key: 'property',
        filter: {
          type,
          begin: params?.begin,
          end: params?.end,
          page: getPage(params?.offset, params?.limit),
        },
      }),
    );

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getFillById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.getFillId(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const cancelWithdrawal = async (id: string) => {
  try {
    dispatch(startWaiting());
    await withdrawalEndpoint.deleteWithdrawalsId(id);
  } finally {
    dispatch(finishWaiting());
  }
};

export const resendWithdrawalVerify = async (id: string) => {
  try {
    dispatch(startWaiting());
    await withdrawalEndpoint.patchWithdrawalsIdResend(id);
  } finally {
    dispatch(finishWaiting());
  }
};
