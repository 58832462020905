import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<WithdrawalRecord>;
  navigateCallback: (record: WithdrawalRecord) => void;
  onCancel: (id: string) => void;
  onVerifyClick: (record: WithdrawalRecord) => void;
};

const WithdrawalTable = ({ data, navigateCallback, onCancel, onVerifyClick }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (record: WithdrawalRecord) => () => {
    navigateCallback(record);
  };

  const elementMapFunction = (
    record: WithdrawalRecord,
    index: number,
    array: Array<WithdrawalRecord>,
  ) => (
    <div key={record.id} className="flex flex-col">
      <div className="flex flex-row">
        <Body size="m" className="box-border w-[calc(100%/11)] break-words pr-[10px]">
          {record.coin.toUpperCase()}
        </Body>
        <Body size="m" className="box-border w-[calc(100%/11)] break-words pr-[10px]">
          <div>{t(`record.desc.statusDisplay.${record.status}`)}</div>
          {record.status === 'created' && (
            <Button appearance="text" type="button" onClick={() => onVerifyClick(record)}>
              {t('record.desc.verify')}
            </Button>
          )}
        </Body>
        <Body size="m" className="box-border w-[calc(100%/11*2)] break-words pr-[10px]">
          {bnFormat(record.amount)}
        </Body>
        <Body size="m" className="box-border w-[calc(100%/11*2)] break-words pr-[10px]">
          {format(Date.parse(record.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </Body>
        <Body size="m" className="box-border w-[calc(100%/11*4)] break-words pr-[10px]">
          {record.address}
        </Body>
        <div className="box-border flex w-[calc(100%/11)] items-center justify-end gap-[10px] break-words pr-[10px]">
          {(record.status === 'created' || record.status === 'verified') && (
            <Button
              appearance="text"
              className="text-text-alert"
              onClick={() => onCancel(record.id)}
            >
              {t('act.cancel')}
            </Button>
          )}
          <Button appearance="text" onClick={onDetailClick(record)}>
            {t('record.act.detail')}
          </Button>
        </div>
      </div>
      <Divider
        theme="sub"
        className={classNames('my-[15px]', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex flex-row text-text-table-thead">
        <Body size="m" bold className="w-[calc(100%/11)]">
          {t('record.desc.coin')}
        </Body>
        <Body size="m" bold className="w-[calc(100%/11)]">
          {t('record.desc.status')}
        </Body>
        <Body size="m" bold className="w-[calc(100%/11*2)]">
          {t('record.desc.amount')}
        </Body>
        <Body size="m" bold className="w-[calc(100%/11*2)]">
          {t('record.desc.date')}
        </Body>
        <Body size="m" bold className="w-[calc(100%/11*4)]">
          {t('record.desc.address')}
        </Body>
      </div>
      <Divider theme="sub" className="my-[15px]" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default WithdrawalTable;
