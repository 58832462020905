import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { Contact } from 'src/model/Contact';

type Props = {
  contactList: Contact[];
  onDelete: (id: string) => void;
};

const Table = ({ contactList, onDelete }: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (contact: Contact, index: number, array: Contact[]) => (
    <div key={contact.id}>
      <Body size="m" className="flex flex-row py-[16px]">
        <div className="w-1/6">{contact.coin.toUpperCase()}</div>
        <div className="box-border w-1/6 break-words pr-[10px]">{contact.label}</div>
        <div className="box-border w-1/2 break-words pr-[10px]">
          {contact.address}
          <span className="ml-[10px] text-text-warning">{contact.network?.toUpperCase()}</span>
        </div>
        <div>
          <Button
            appearance="text"
            className="text-text-alert"
            onClick={() => onDelete(contact.id)}
          >
            {t('addressManagement.act.delete')}
          </Button>
        </div>
      </Body>
      <Divider
        theme="sub"
        className={classNames({
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return (
    <div className="px-[40px] pb-[9px] pt-[10px]">
      <Body size="m" className="flex flex-row py-[16px] text-text-table-thead">
        <div className="w-1/6">{t('addressManagement.desc.coin')}</div>
        <div className="w-1/6">{t('addressManagement.desc.label')}</div>
        <div className="w-1/2">{t('addressManagement.desc.address')}</div>
      </Body>
      <Divider theme="sub" />
      {contactList.map(elementMapFunction)}
    </div>
  );
};

export default Table;
