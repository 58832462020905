import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Announcement } from 'src/model/Announcement';
import { Filter } from 'src/model/Filter';
import { ProcessedNotification } from 'src/model/Notification';

export type UiState = {
  workload: number;
  showSnackbar: boolean;
  snackbar: { message: string; severity?: 'notify' | 'alert' };
  notification: ProcessedNotification[];
  unreadAnnouncement: number;
  pinnedAnnouncement: Announcement[];
  demand: { base: string; amount: string } | null;
  filter: Filter;
};

const initialState: UiState = {
  workload: 0,
  showSnackbar: false,
  snackbar: { message: '' },
  notification: [],
  unreadAnnouncement: 0,
  pinnedAnnouncement: [],
  demand: null,
  filter: {
    adminFill: {},
    adminTrade: {},
    adminOrder: {},
    adminBalance: {},
    adminDeposit: {},
    adminWithdrawal: {},
    adminParentRebate: {},
    adminAgentRebate: {},
    adminUserAsk: {},
    adminUserBid: {},
    adminUserAdmin: {},
    adminMessage: {},
    adminKyc: {},
    property: {},
    record: {},
    orderAsk: {},
    orderBid: {},
    orderHistory: {},
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    startWaiting: (state: UiState) => {
      state.workload = state.workload + 1;
    },
    finishWaiting: (state: UiState) => {
      state.workload = state.workload - 1;
    },
    openSnackbar: (state: UiState, action: PayloadAction<UiState['snackbar']>) => {
      state.showSnackbar = true;
      state.snackbar = action.payload;
    },
    dismissSnackbar: (state: UiState) => {
      state.showSnackbar = false;
    },
    pushNotification: (state: UiState, action: PayloadAction<ProcessedNotification[]>) => {
      state.notification = [...action.payload, ...state.notification];
    },
    reduceNotification: (state: UiState, action: PayloadAction<number>) => {
      const temp = [...state.notification];
      temp.splice(action.payload, 1);
      state.notification = temp;
    },
    setUnreadAnnouncement: (state: UiState, action: PayloadAction<number>) => {
      state.unreadAnnouncement = action.payload;
    },
    reduceUnreadAnnouncement: (state: UiState) => {
      state.unreadAnnouncement = state.unreadAnnouncement - 1;
    },
    setPinnedAnnouncement: (state: UiState, action: PayloadAction<Announcement[]>) => {
      state.pinnedAnnouncement = action.payload;
    },
    setDemand: (state: UiState, action: PayloadAction<{ base: string; amount: string } | null>) => {
      state.demand = action.payload;
    },
    updateFilter: (
      state: UiState,
      action: PayloadAction<{ key: keyof Filter; filter: Filter[keyof Filter] }>,
    ) => {
      state.filter[action.payload.key] = {
        ...state.filter[action.payload.key],
        ...action.payload.filter,
      };
    },
  },
});

export const {
  startWaiting,
  finishWaiting,
  openSnackbar,
  dismissSnackbar,
  pushNotification,
  reduceNotification,
  setUnreadAnnouncement,
  setPinnedAnnouncement,
  reduceUnreadAnnouncement,
  setDemand,
  updateFilter,
} = uiSlice.actions;

export default uiSlice.reducer;
