import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormSelect from 'src/component/FormSelect';
import Modal from 'src/component/Modal';
import SelectOption from 'src/component/SelectOption';
import H3 from 'src/component/typography/H3';
import { AddAddressForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { createContact } from 'src/service/propertyService';

type Props = {
  open: boolean;
  handleClose: () => void;
  onFinish: () => void;
};

const AddWithdrawalAddressModal = ({ open, handleClose, onFinish }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { crypto } = useSelector((rootState: RootState) => rootState.coin);
  const [showNetwork, setShowNetwork] = useState<boolean>(true);
  const newAddressFormMethods = useForm<AddAddressForm>();
  const formData = useWatch({ control: newAddressFormMethods.control });

  const onNewAddressFormSubmit = (data: AddAddressForm) => {
    handleClose();
    createContact(data)
      .then(() => {
        newAddressFormMethods.reset();
        onFinish();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  useEffect(() => {
    if (formData.coin === 'usdt') {
      setShowNetwork(true);
      newAddressFormMethods.setValue('network', 'trc20');
    } else {
      setShowNetwork(false);
      newAddressFormMethods.setValue('network', '');
    }
  }, [formData.coin]);

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form
        className="flex flex-col gap-[25px]"
        methods={newAddressFormMethods}
        onSubmit={onNewAddressFormSubmit}
      >
        <H3>{t('addressManagement.desc.addWithdrawalAddress')}</H3>
        <FormSelect
          label={t('addressManagement.desc.coin')}
          name="coin"
          defaultValue="usdt"
          required
          asterisked
        >
          {crypto.map((v) => (
            <SelectOption key={v.id} value={v.id}>
              {v.id.toUpperCase()}
            </SelectOption>
          ))}
        </FormSelect>
        {showNetwork && (
          <FormSelect
            label={t('addressManagement.desc.network')}
            name="network"
            defaultValue="trc20"
            required
            asterisked
          >
            {crypto
              .find((v) => v.id === 'usdt')
              ?.networks.map((v) => (
                <SelectOption key={v.network} value={v.network}>
                  {v.network.toUpperCase()}
                </SelectOption>
              ))}
          </FormSelect>
        )}
        <FormInput
          label={t('addressManagement.desc.label')}
          name="label"
          maxLength={64}
          required
          asterisked
        />
        <FormInput
          label={t('addressManagement.desc.address')}
          name="address"
          maxLength={256}
          required
          asterisked
        />
        <div className="mt-[15px] text-end">
          <Button type="submit">{t('addressManagement.act.create')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddWithdrawalAddressModal;
