import { t } from 'i18next';
import {
  GetDemandResponse,
  GetOpenOrdersParams,
  GetOpenOrdersResponse,
  GetOrderBidPriceResponse,
  GetOrderFillsAdminResponse,
  GetOrderFillsResponse,
  GetOrderIdResponse,
  GetOrdersParams,
  GetOrdersResponse,
  PatchOrderSuspendRequest,
  PostOrderRequest,
  PostOrderResponse,
  PutOrderRequest,
  PutOrderResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getUserOrderId = async <T = GetOrderIdResponse>(id: string) => {
  try {
    return await http.authGet<T>(`orders/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const createOrder = async (data: PostOrderRequest) => {
  try {
    return await http.authPost<PostOrderResponse, PostOrderRequest>('orders', { data });
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 400 && error.response.data.code === 5)
      throw t('error.order.badOrderLimit');
    if (error.response?.status === 400 && error.response.data.code === 10)
      throw t('error.order.badPrice');
    if (error.response?.status === 400 && error.response.data.code === 11)
      throw t('error.order.noOrderToMatch');
    throw defaultErrorMessage(error);
  }
};

const updateOrder = async (id: string, data: PutOrderRequest) => {
  try {
    return await http.authPut<PutOrderResponse, PutOrderRequest>(`orders/${id}`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const deleteOrder = async (id: string) => {
  try {
    return await http.authDelete(`orders/${id}`);
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 400 && error.response.data.code === 0)
      throw t('error.order.notDeletable');
    throw defaultErrorMessage(error);
  }
};

const suspendOrder = async (id: string, data: PatchOrderSuspendRequest) => {
  try {
    return await http.authPatch<unknown, PatchOrderSuspendRequest>(`orders/${id}/suspend`, {
      data,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const unsuspendOrder = async (id: string) => {
  try {
    return await http.authPatch(`orders/${id}/unsuspend`);
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 400 && error.response.data.code === 0)
      throw t('error.order.notUnsuspendable');
    throw defaultErrorMessage(error);
  }
};

const getOpenOrders = async (params?: GetOpenOrdersParams) => {
  try {
    return await http.authGet<GetOpenOrdersResponse>('open-orders', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getOrders = async <T = GetOrdersResponse>(
  params?: GetOrdersParams,
  headers?: { [key: string]: string },
) => {
  try {
    return await http.authGet<T>('orders', { params, headers });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getOrderBidPrice = async (base: string, quote: string) => {
  try {
    return await http.authGet<GetOrderBidPriceResponse>(`price/${base}/${quote}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getOrderFills = async (id: string) => {
  try {
    return await http.authGet<GetOrderFillsResponse>(`orders/${id}/fills`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getOrderFillsAdmin = async (id: string) => {
  try {
    return await http.authGet<GetOrderFillsAdminResponse>(`orders/${id}/fills`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchOrderCancel = async (id: string) => {
  try {
    await http.authPatch(`orders/${id}/cancel`);
  } catch (e) {
    const error = axiosError(e);
    if (error.response?.status === 400 && error.response.data.code === 0)
      throw t('error.order.alreadyCompleted');
    if (error.response?.status === 409 && error.response.data.code === 0)
      throw t('error.order.notCancelable');
    throw defaultErrorMessage(error);
  }
};

const getDemand = async () => {
  try {
    return await http.authGet<GetDemandResponse>('demand');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getUserOrderId,
  createOrder,
  updateOrder,
  deleteOrder,
  suspendOrder,
  unsuspendOrder,
  getOpenOrders,
  getOrders,
  getOrderBidPrice,
  getOrderFills,
  getOrderFillsAdmin,
  patchOrderCancel,
  getDemand,
};
