import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import H3 from 'src/component/typography/H3';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';

const Support = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { IcContact, IcPrivacypolicy, IcTermofuse } = useContext(ThemeContext).image;

  return (
    <div>
      <BackButton />
      <H3 className="mt-[10px] sm:mt-[20px]">{t('support.heading')}</H3>
      <div className="mt-[28px] flex flex-col gap-[20px] xs:flex-row xs:gap-[30px]">
        <div
          className="cursor-pointer rounded-[4px] bg-bg-content py-[40px] text-center xs:flex-1"
          onClick={() => navigate(Page.ContactUs)}
        >
          <img className="h-[36px] w-[36px] xs:h-[72px] xs:w-[72px]" src={IcContact} />
          <div>{t('support.desc.contactUs')}</div>
        </div>
        <div
          className="cursor-pointer rounded-[4px] bg-bg-content py-[40px] text-center xs:flex-1"
          onClick={() => navigate(Page.TermsOfUse)}
        >
          <img className="h-[36px] w-[36px] xs:h-[72px] xs:w-[72px]" src={IcTermofuse} />
          <div>{t('support.desc.terms')}</div>
        </div>
        <div
          className="cursor-pointer rounded-[4px] bg-bg-content py-[40px] text-center xs:flex-1"
          onClick={() => navigate(Page.Privacy)}
        >
          <img className="h-[36px] w-[36px] xs:h-[72px] xs:w-[72px]" src={IcPrivacypolicy} />
          <div>{t('support.desc.privacyPolicy')}</div>
        </div>
      </div>
    </div>
  );
};

export default Support;
