import { useTranslation } from 'react-i18next';
import { Language } from 'src/constant/Language';
import { updateLocale } from 'src/service/menuService';
import ListItem from './ListItem';
import Popover from './Popover';

type Props = { open: boolean; onClose: () => void; anchorEl: Element | null };

const MenuLanguage = ({ open, onClose, anchorEl }: Props) => {
  const { i18n } = useTranslation();

  const onClick = (langCode: string) => () => {
    i18n.changeLanguage(langCode);
    updateLocale(langCode);
    onClose();
  };

  return (
    <Popover open={open} onClose={onClose} anchorEl={anchorEl} className="py-[5px]">
      {Language.map((v) => (
        <ListItem
          key={v.code}
          onClick={onClick(v.code.toLowerCase())}
          focus={i18n.language === v.code.toLowerCase()}
        >
          {v.name}
        </ListItem>
      ))}
    </Popover>
  );
};

export default MenuLanguage;
