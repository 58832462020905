import { t } from 'i18next';
import balanceEndpoint from 'src/api/balanceEndpoint';
import bankAccountEndpoint from 'src/api/bankAccountEndpoint';
import exchangeRateEndpoint from 'src/api/exchangeRateEndpoint';
import fillsEndpoint from 'src/api/fillsEndpoint';
import orderEndpoint from 'src/api/orderEndpoint';
import { Action } from 'src/constant/Order';
import {
  GetBalancesIdResponse,
  GetBankAccountResponse,
  GetOpenOrdersParams,
  GetOrdersParams,
  PostOrderRequest,
  PutOrderRequest,
} from 'src/model/Api';
import { BidOrder, UserOrder } from 'src/model/Order';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { file2Base64 } from 'src/util/fileConverter';
import { getPage } from 'src/util/paginationHelper';
import { loadCoins } from './appService';

export const getOpenOrders = async (params?: GetOpenOrdersParams) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOpenOrders(params);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getFills = async (
  status?: string,
  sorting?: string,
  paginationParams?: PaginationParams,
) => {
  if (status !== 'sent,executed')
    dispatch(
      updateFilter({
        key: 'orderAsk',
        filter: {
          status,
          sorting,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
        },
      }),
    );

  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.getFills({
      ...paginationParams,
      status: status === 'all' ? undefined : status,
      sort: sorting === 'default' ? undefined : sorting,
      order: 'desc',
    });

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getFillById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.getFillId(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getBidOrders = async (
  params: {
    quote?: string;
    sorting: string;
  },
  paginationParams?: PaginationParams,
) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOrders<Array<BidOrder>>({
      ...paginationParams,
      quote: params.quote,
      base: params.quote ? 'usdt' : undefined,
      status: 'open,executed',
      sort: params.sorting === 'default' ? undefined : params.sorting,
      order: 'desc',
    });

    dispatch(
      updateFilter({
        key: 'orderBid',
        filter: {
          quote: params.quote,
          sorting: params.sorting,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
        },
      }),
    );

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getBidOrdersHistory = async (
  status: string,
  sorting: string,
  pagination: PaginationParams,
) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOrders<Array<BidOrder>>({
      ...pagination,
      status: status === 'all' ? undefined : status,
      sort: sorting === 'default' ? undefined : sorting,
      order: 'desc',
    });

    dispatch(
      updateFilter({
        key: 'orderHistory',
        filter: {
          status,
          sorting,
          begin: pagination.begin,
          end: pagination.end,
          page: getPage(pagination?.offset, pagination?.limit),
        },
      }),
    );

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getUserOrders = async (sorting: string, params?: GetOrdersParams) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOrders<Array<UserOrder>>({
      ...params,
      order: 'desc',
      sort: sorting === 'default' ? undefined : sorting,
    });

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getBidOrderId = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getUserOrderId<BidOrder>(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getOrderFills = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOrderFills(id);

    return {
      executedFills: res.data.filter((v) => v.status === 'executed' || v.status === 'canceled'),
      sentReceviedFills: res.data.filter((v) => v.status === 'sent' || v.status === 'received'),
      cancelable:
        res.data.length ===
        res.data.filter((v) => v.status === 'executed' || v.status === 'received').length,
    };
  } finally {
    dispatch(finishWaiting());
  }
};

export const createOrder = async (data: PostOrderRequest) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.createOrder(data);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getExchangeRate = async (base: string, quote: string) => {
  try {
    dispatch(startWaiting());
    const res = await exchangeRateEndpoint.getExchangeRate(base, quote);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const initTrading = async (
  base: string,
): Promise<[GetBankAccountResponse, GetBalancesIdResponse]> => {
  try {
    dispatch(startWaiting());
    const [resBankAccount, resBalance] = await Promise.all([
      bankAccountEndpoint.getBankAccountList(),
      balanceEndpoint.getBalance(base),
      loadCoins(),
    ]);

    return [resBankAccount.data, resBalance.data];
  } finally {
    dispatch(finishWaiting());
  }
};

const suspendOrder = async (id: string, suspendedAt?: string) => {
  try {
    dispatch(startWaiting());
    await orderEndpoint.suspendOrder(id, { suspendedAt });
  } finally {
    dispatch(finishWaiting());
  }
};

const unsuspendOrder = async (id: string) => {
  try {
    dispatch(startWaiting());
    await orderEndpoint.unsuspendOrder(id);
  } finally {
    dispatch(finishWaiting());
  }
};

const deleteOrder = async (id: string) => {
  try {
    dispatch(startWaiting());
    await orderEndpoint.deleteOrder(id);
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateOrder = async (
  id: string,
  action: 'saveChange' | 'saveAndOpen',
  data: PutOrderRequest,
) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.updateOrder(id, {
      ...data,
      suspendedAt: action === 'saveAndOpen' ? data.suspendedAt : undefined,
    });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const cancelOrder = async (id: string) => {
  try {
    dispatch(startWaiting());
    await orderEndpoint.patchOrderCancel(id);
  } finally {
    dispatch(finishWaiting());
  }
};

export const actionOrder = async (action: Action, id: string) => {
  if (action === Action.Suspend) await suspendOrder(id);
  else if (action === Action.Unsuspend) await unsuspendOrder(id);
  else if (action === Action.Delete) await deleteOrder(id);
};

export const getOrderBidPrice = async (base: string, quote: string) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOrderBidPrice(base, quote);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const initOrderBid = async () => {
  try {
    dispatch(startWaiting());
    await loadCoins();
  } finally {
    dispatch(finishWaiting());
  }
};

export const createBidOrder = async (data: PostOrderRequest) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.createOrder(data);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const uploadReceipt = async (id: string, file: File, note?: string) => {
  try {
    dispatch(startWaiting());

    if (!['image/jpeg', 'image/png'].includes(file.type))
      throw t('orderDetail.desc.unacceptedFileType');
    if (file.size > 16 * 1024 * 1024) throw t('orderDetail.desc.fileTooLarge');

    const receipt = await file2Base64(file);
    await fillsEndpoint.patchFillsSend(id, {
      note,
      receipt,
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const confirmFill = async (id: string, code: string) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.patchFillsReceive(id, code);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const unsendFill = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.patchFillsUnsend(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const rejectFill = async (id: string, code: string) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.patchFillsIdReject(id, code);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
