import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = HTMLAttributes<HTMLHeadingElement>;

const H5 = ({ className, ...props }: Props) => (
  <h5 className={twMerge('m-0 text-[20px] font-bold leading-[1.4]', className)} {...props} />
);

export default H5;
