import classNames from 'classnames';
import { format } from 'date-fns';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { BalanceTransaction } from 'src/model/Property';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BalanceTransaction[];
  navigateCallback: (transaction: BalanceTransaction) => void;
};

const Card = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (transaction: BalanceTransaction) => () => {
    navigateCallback(transaction);
  };

  const elementMapFunction = (
    transaction: BalanceTransaction,
    index: number,
    array: BalanceTransaction[],
  ) => (
    <div key={transaction.id}>
      <div className="flex flex-col gap-[4px] pt-[15px]">
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('propertyDetail.desc.amount')}
          </Body>
          <Body size="m">{bnFormat(transaction.amount)}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('propertyDetail.desc.balance')}
          </Body>
          <Body size="m">{bnFormat(transaction.balance)}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('propertyDetail.desc.information')}
          </Body>
          <Body size="m" className="flex flex-row gap-[10px]">
            {t(`propertyDetail.desc.transactionType.${camelCase(transaction.type)}`)}
            {!['manipulation', 'on-chain-fee', 'init', 'agent-rebate'].includes(
              transaction.type,
            ) && (
              <Button appearance="text" onClick={onDetailClick(transaction)}>
                {t('propertyDetail.act.detail')}
              </Button>
            )}
          </Body>
        </div>
        <Body size="s" className="flex flex-col gap-[2px] text-text-table-thead">
          <div>{format(Date.parse(transaction.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </Body>
      </div>
      <Divider
        theme="sub"
        className={classNames('mt-[13px]', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
