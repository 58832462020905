import { useContext, useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import Alert from 'src/component/Alert';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { Severity } from 'src/constant/Notification';
import { ThemeContext } from 'src/context/ThemeContext';
import { openSnackbar } from 'src/redux/uiSlice';
import { getInvitationCode } from 'src/service/accountService';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const InvitationModal = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcCopy } = useContext(ThemeContext).image;
  const [invitationCode, setInvitationCode] = useState<string>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const url = useMemo(
    () => `${location.origin}/auth/register?invitation=${invitationCode}`,
    [invitationCode],
  );

  useEffect(() => {
    if (open === false) return;
    getInvitationCode()
      .then((res) => setInvitationCode(res.id))
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
        handleClose();
      });
  }, [refresh, open]);

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <div>
        <H3 className="mb-[20px]">{t('account.desc.inviteCode')}</H3>
        <Body>{t('account.desc.inviteHint1')}</Body>
        <Body
          bold
          className="mb-[25px] mt-[15px] flex items-center justify-between gap-[5px] rounded-[4px] bg-bg-displayfield py-[16px] pl-[15px] pr-0 text-text-referral"
        >
          <div>{invitationCode}</div>
          <CopyToClipboard onCopy={onCopy} text={invitationCode ?? ''}>
            <img src={IcCopy} className="cursor-pointer pr-[10px]" />
          </CopyToClipboard>
        </Body>
        <Body>{t('account.desc.inviteHint2')}</Body>
        <div className="my-[15px] flex gap-[15px]">
          <div className="flex items-center justify-center bg-white p-[5px]">
            <QRCode value={url} size={110} />
          </div>
          <Body
            size="s"
            bold
            className="flex h-fit items-center justify-between gap-[10px] rounded-[4px] bg-bg-displayfield py-[10px] pl-[15px] pr-[0px] text-text-referral"
          >
            <div className="break-all">{url}</div>
            <CopyToClipboard onCopy={onCopy} text={url}>
              <img src={IcCopy} className="cursor-pointer pr-[10px]" />
            </CopyToClipboard>
          </Body>
        </div>
        <Alert severity={Severity.Warning}>{t('account.desc.expiration')}</Alert>
        <div className="mt-[40px] flex justify-end gap-[20px]">
          <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
            {t('account.act.refresh')}
          </Button>
          <Button onClick={() => handleClose()}>{t('act.ok')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default InvitationModal;
