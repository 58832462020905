import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Pagination from 'src/component/Pagination';
import RoundDateFilter from 'src/component/RoundDateFilter';
import RoundSelect from 'src/component/RoundSelect';
import SelectOption from 'src/component/SelectOption';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import H5 from 'src/component/typography/H5';
import { Ui } from 'src/constant/Env';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcUsdt from 'src/image/ic-usdt.svg';
import { Balance } from 'src/model/Balance';
import { BalanceTransaction } from 'src/model/Property';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getBalance, getBalanceTransactions, getFillById } from 'src/service/propertyService';
import { bnFormat } from 'src/util/bigNumber';
import Card from './component/Card';
import RedirectModal from './component/RedirectModal';
import Table from './component/Table';

const Property = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { IcEmpty } = useContext(ThemeContext).image;
  const { property: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);
  const { offset, limit, setCount, paginationProps } = usePagination({ defaultPage: filter.page });
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [balance, setBalance] = useState<Balance>();
  const [filterType, setFilterType] = useState<string>(filter.type ?? 'all');
  const [transactionArray, setTransactionArray] = useState<Array<BalanceTransaction>>();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const options = useMemo(() => {
    if (Ui === 'seller' && accountInfo?.isAgent === true)
      return ['all', 'ask', 'deposit', 'withdrawal', 'manipulation', 'agent-rebate'];
    if (Ui === 'seller' || Ui === 'buyer')
      return ['all', 'ask', 'deposit', 'withdrawal', 'manipulation'];

    return [];
  }, []);

  useEffect(() => {
    getBalance('usdt')
      .then((res) => setBalance(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  useEffect(() => {
    getBalanceTransactions(filterType, { begin, end, offset, limit })
      .then((res) => {
        setTransactionArray(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [begin, end, offset, limit, filterType]);

  const navigateCallback = (transaction: BalanceTransaction) => {
    switch (transaction.type) {
      case 'ask': {
        navigate(`${Page.Order}/${transaction.referenceId}`);
        break;
      }
      case 'bid': {
        getFillById(transaction.referenceId)
          .then((res) => navigate(`${Page.Order}/${res.orderId}`))
          .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
        break;
      }
      case 'withdrawal':
      case 'withdrawal-fee': {
        navigate(`${Page.Withdrawal}/${transaction.referenceId}`);
        break;
      }
      case 'deposit': {
        navigate(`${Page.Deposit}/${transaction.referenceId}`);
        break;
      }
    }
  };

  const onWithdrawalClick = () => {
    if (accountInfo?.otpActivated === true) navigate(`${Page.Withdrawal}?coinId=USDT`);
    else setOpen(true);
  };

  const onTypeFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterType(value);
  };

  if (!ready) return <div />;

  return (
    <div>
      <div className="mb-[10px]">
        <BackButton />
      </div>
      <H3 className="mt-[10px] hidden xs:block xs:pb-[30px] sm:mt-[20px]">
        {t('propertyDetail.heading', { coin: 'USDT' })}
      </H3>
      <div className="rounded-[12px] bg-bg-balancecard p-[25px] text-text-balancecard-value">
        <div className="flex flex-row justify-between">
          <H5 className="flex flex-row items-center justify-between gap-[10px]">
            <img src={IcUsdt} />
            USDT
          </H5>
          <div className="hidden xs:flex xs:flex-col xs:items-end">
            <Body size="s" className="text-text-balancecard-title">
              {t('propertyDetail.desc.total')}
            </Body>
            <H3>{bnFormat(balance?.total ?? null)}</H3>
          </div>
        </div>
        <div className="mt-[10px] xs:hidden">
          <Body size="s" className="text-text-balancecard-title">
            {t('propertyDetail.desc.total')}
          </Body>
          <Body size="l" bold>
            {bnFormat(balance?.total ?? null)}
          </Body>
        </div>
        <div className="mt-[10px]">
          <Body size="s" className="text-text-balancecard-title">
            {t('propertyDetail.desc.available')}
          </Body>
          <Body size="m">{bnFormat(balance?.free ?? null)}</Body>
        </div>
        <div className="mt-[10px]">
          <Body size="s" className="text-text-balancecard-title">
            {t('propertyDetail.desc.locked')}
          </Body>
          <Body size="m">{bnFormat(balance?.locked ?? null)}</Body>
        </div>
        <div className="mt-[15px] flex flex-row justify-end gap-[12px]">
          <Button size="small" onClick={() => navigate(`${Page.Deposit}?coinId=USDT`)}>
            {t('propertyDetail.act.deposit')}
          </Button>
          <Button size="small" onClick={onWithdrawalClick}>
            {t('propertyDetail.act.withdrawal')}
          </Button>
        </div>
      </div>
      <Body
        size="m"
        className="mt-[15px] rounded-[12px] bg-bg-content px-[15px] py-[25px] md:px-[40px] md:py-[25px]"
      >
        <div className="flex flex-wrap gap-x-[15px]">
          <RoundDateFilter onChange={() => paginationProps.setPage(1)} {...dateFilterProps} />
          <div className="mb-[15px]">
            <RoundSelect
              label={t('property.desc.type')}
              defaultValue={filterType}
              onChange={onTypeFilterChange}
            >
              {options.map((v) => (
                <SelectOption key={v} value={v}>
                  {t(`property.desc.typeOption.${v}`)}
                </SelectOption>
              ))}
            </RoundSelect>
          </div>
        </div>
        <Divider theme="sub" />
        {transactionArray?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {transactionArray &&
          transactionArray?.length > 0 &&
          (isBiggerThanMd ? (
            <Table data={transactionArray} navigateCallback={navigateCallback} />
          ) : (
            <Card data={transactionArray} navigateCallback={navigateCallback} />
          ))}
      </Body>
      <div className="mt-[15px] flex flex-row-reverse ">
        <Pagination {...paginationProps} />
      </div>
      <RedirectModal open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default Property;
