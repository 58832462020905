import { useCallback, useContext, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'src/component/Alert';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import H5 from 'src/component/typography/H5';
import { Severity } from 'src/constant/Notification';
import { ThemeContext } from 'src/context/ThemeContext';
import IcDelete from 'src/image/ic-delete.svg';
import { KycForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { uploadKycs } from 'src/service/kycService';

const KycVerification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<KycForm>();
  const { IcUpload } = useContext(ThemeContext).image;
  const { accountInfo: info } = useSelector((rootState: RootState) => rootState.auth);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploaded, setUploaded] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) setUploaded([...(uploaded ?? []), acceptedFiles[0]]);
    },
    [uploaded],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/png': [], 'image/jpeg': [], 'image/webp': [] },
    multiple: false,
  });

  const onSubmit = (data: KycForm) => {
    if (!uploaded) return;
    uploadKycs(data, uploaded).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <BackButton />
      <H3 className="mt-[10px] sm:mt-[20px]">{t('kycVerification.heading')}</H3>
      {info?.kycStatus === 'none' && (
        <Alert severity={Severity.Info} className="mt-[10px]">
          {t('kycVerification.desc.alertNone')}
        </Alert>
      )}
      {info?.kycStatus === 'failed' && (
        <>
          <Body className="mt-[10px] text-text-alert">{t('kycVerification.desc.failed')}</Body>
          <Alert severity={Severity.Error} className="mt-[10px]">
            {t('kycVerification.desc.alertFailed')}
          </Alert>
        </>
      )}
      {info?.kycStatus === 'reviewing' && (
        <>
          <Body className="mt-[10px] text-text-warning">{t('kycVerification.desc.reviewing')}</Body>
          <Alert severity={Severity.Info} className="mt-[10px]">
            {t('kycVerification.desc.alertReviewing')}
          </Alert>
        </>
      )}
      {info?.kycStatus !== 'reviewing' && (
        <Form methods={methods} onSubmit={onSubmit} className="xs:px-[15px] md:px-[30px]">
          <div className="mt-[30px] flex flex-col gap-[25px] md:flex-row md:gap-[60px]">
            <div className="flex-1">
              <FormInput name="name" label={t('kycVerification.desc.name')} required asterisked />
            </div>
            <div className="flex-1">
              <FormInput name="pin" label={t('kycVerification.desc.pin')} required asterisked />
            </div>
          </div>
          <H5 className="mb-[10px] mt-[55px]">
            {t('kycVerification.desc.upload')}
            <span className="text-text-alert">*</span>
          </H5>
          <Body size="m" className="text-text-secondary">
            {t('kycVerification.desc.uploadDesc')}
          </Body>
          <div className="mt-[30px] flex flex-col flex-wrap items-center gap-[30px] xs:flex-row xs:items-start">
            {uploaded.map((v, i) => (
              <div key={i}>
                <div className="flex h-[160px] w-[240px] flex-col items-center justify-center gap-[5px] rounded border-[3px] border-solid border-border-uploadpic">
                  <img src={URL.createObjectURL(v)} className="max-h-full max-w-full" />
                </div>
                <div className="mt-[5px] flex justify-center">
                  <div
                    className="flex cursor-pointer items-center"
                    onClick={() => setUploaded(uploaded.filter((o) => o !== v))}
                  >
                    <img src={IcDelete} />
                    <Body size="m" className="text-silver-500">
                      {t('kycVerification.act.delete')}
                    </Body>
                  </div>
                </div>
              </div>
            ))}
            {uploaded.length < 10 && (
              <div>
                <div
                  className="flex h-[160px] w-[240px] cursor-pointer flex-col items-center justify-center gap-[5px] rounded border-[3px] border-solid border-border-uploadpic"
                  onClick={() => fileInputRef.current?.click()}
                  {...getRootProps()}
                >
                  <img src={IcUpload} />
                  <Body size="m">{t('kycVerification.act.upload')}</Body>
                  <input {...getInputProps()} />
                </div>
              </div>
            )}
          </div>
          <div className="mt-10 text-right">
            <Button type="submit" disabled={!uploaded || uploaded.length === 0}>
              {t('act.submit')}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default KycVerification;
