import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import FormSelect from 'src/component/FormSelect';
import SelectOption from 'src/component/SelectOption';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { Page } from 'src/constant/Page';
import { BidRequestForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { createBidOrder, getOrderBidPrice, initOrderBid } from 'src/service/orderService';
import { bn, bnFormat } from 'src/util/bigNumber';
import NoResultModal from './component/NoResultModal';

const TradeBid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { crypto, fiat } = useSelector((state: RootState) => state.coin);
  const hint = useMemo(() => {
    if (crypto.length === 0) return '';
    const minOrderAmount = crypto[0].minOrderAmount;
    const maxOrderAmount = crypto[0].maxOrderAmount;
    const coin = crypto.length > 0 ? crypto[0].id.toUpperCase() : '';
    if (minOrderAmount && maxOrderAmount)
      return `${t('trade.desc.orderAmount')} ${bnFormat(minOrderAmount)} ${coin} - ${bnFormat(
        maxOrderAmount,
      )} ${coin}`;
    if (minOrderAmount && maxOrderAmount === null)
      return `${t('trade.desc.orderAmount')} ${bnFormat(minOrderAmount)} ${coin} -`;
    if (minOrderAmount === null && maxOrderAmount)
      return `${t('trade.desc.orderAmount')} 0 - ${bnFormat(maxOrderAmount)} ${coin}`;

    return '';
  }, [t, crypto]);
  const methods = useForm<BidRequestForm>();
  const [marketPrice, setMarketPrice] = useState<string>();
  const [isNoResultShowing, setNoResultShowing] = useState<boolean>(false);
  const formData = useWatch({ control: methods.control });
  const { dirtyFields } = useFormState({ control: methods.control });
  const selectedFiat = useMemo(
    () => fiat.find((v) => v.id === formData.quote),
    [formData.quote, fiat],
  );

  const onSubmit = (data: BidRequestForm) => {
    createBidOrder({
      base: 'usdt',
      quote: data.quote,
      price: Number(marketPrice),
      openAmount: Number(data.orderAmount),
      clientId: Date.now().toString(),
    })
      .then((res) => {
        navigate(`${Page.Order}/${res.id}`, { state: res });
      })
      .catch((e) => {
        if (e === t('error.order.noOrderToMatch')) setNoResultShowing(true);
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  };

  useEffect(() => {
    initOrderBid().catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  useEffect(() => {
    if (fiat.length > 0) methods.setValue('quote', fiat[0].id);
  }, [fiat]);

  useEffect(() => {
    if (!formData.quote) return;
    methods.resetField('orderAmount');
    methods.resetField('orderPrice');
    getOrderBidPrice('usdt', formData.quote)
      .then((res) => setMarketPrice(res.price))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [formData.quote]);

  useEffect(() => {
    if (dirtyFields.orderPrice !== true || marketPrice === undefined || crypto.length === 0) return;
    const quoteNumber = bn(methods.getValues()['orderPrice']);
    methods.resetField('orderAmount');
    if (quoteNumber.isGreaterThan(0))
      methods.setValue('orderAmount', quoteNumber.div(marketPrice).toFixed(crypto[0].decimal, 1), {
        shouldDirty: false,
      });
  }, [formData.orderPrice, dirtyFields.orderPrice, crypto]);

  useEffect(() => {
    if (
      dirtyFields.orderAmount !== true ||
      marketPrice === undefined ||
      fiat.length === 0 ||
      selectedFiat === undefined
    )
      return;
    const baseNumber = bn(methods.getValues()['orderAmount']);
    methods.resetField('orderPrice');
    if (baseNumber.isGreaterThan(0))
      methods.setValue(
        'orderPrice',
        baseNumber.times(marketPrice).toFixed(selectedFiat.decimal, 0),
        {
          shouldDirty: false,
        },
      );
  }, [formData.orderAmount, dirtyFields.orderAmount, fiat]);

  useEffect(() => {
    if (!formData.orderAmount || crypto.length === 0) return;

    const minOrderAmount = crypto[0].minOrderAmount;
    const maxOrderAmount = crypto[0].maxOrderAmount;
    if (minOrderAmount && bn(formData.orderAmount).lt(minOrderAmount))
      methods.setError('orderAmount', {});
    else if (maxOrderAmount && bn(formData.orderAmount).gt(maxOrderAmount))
      methods.setError('orderAmount', {});
    else methods.clearErrors('orderAmount');
  }, [formData.orderAmount, crypto]);

  return (
    <div>
      <BackButton />
      <H3 className="mb-[30px] mt-[10px] sm:mt-[20px]">{t('trade.heading')}</H3>
      {fiat.length > 0 && crypto.length > 0 && (
        <Form
          methods={methods}
          onSubmit={onSubmit}
          className="box-border rounded-[12px] bg-bg-content px-[40px] pb-[70px] pt-[40px] md:w-1/2"
        >
          <div className="flex items-end gap-5">
            <div className="flex-1">
              <FormNumberInput
                label={t('trade.desc.pay')}
                name="orderPrice"
                decimal={selectedFiat?.decimal ?? 0}
                required
              />
            </div>
            <FormSelect name="quote" className="w-[102px]" defaultValue={fiat[0].id}>
              {fiat.map((v) => (
                <SelectOption key={v.id} value={v.id}>
                  {v.id.toUpperCase()}
                </SelectOption>
              ))}
            </FormSelect>
          </div>
          <div className="relative mt-[45px]">
            <FormNumberInput
              name="orderAmount"
              label={t('trade.desc.receive')}
              hint={hint}
              decimal={crypto[0].decimal}
              required
            />
            <div className="absolute right-[0px] top-[28px] text-text-secondary">
              {crypto[0].id.toUpperCase()}
            </div>
          </div>
          <Body size="m" className="mt-[25px] text-text-neutral">{`${t('trade.desc.marketPrice')} ${
            marketPrice ? `${selectedFiat?.icon} ${bnFormat(marketPrice)}` : '-'
          }`}</Body>
          <div className="mt-[20px] text-right">
            <Button
              type="submit"
              disabled={
                !formData.orderAmount ||
                !formData.orderPrice ||
                Object.keys(methods.formState.errors).length !== 0
              }
            >
              {t('trade.act.pairOrder')}
            </Button>
          </div>
        </Form>
      )}
      <NoResultModal open={isNoResultShowing} onClose={() => setNoResultShowing(false)} />
    </div>
  );
};

export default TradeBid;
