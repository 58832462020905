export enum Page {
  Landing = '/',
  SignIn = '/auth/login',
  Register = '/auth/register',
  ForgetPassword = '/auth/forget',
  Verification = '/auth/verification',
  TermsOfUse = '/tou',
  Privacy = '/privacy',
  Trade = '/trade',
  MyTrade = '/trade/my-trade',
  Trading = '/trade/trading',
  Order = '/order',
  OrderHistory = '/order/history',
  Property = '/property',
  Deposit = '/property/deposit',
  Withdrawal = '/property/withdrawal',
  WithdrawalSuccess = '/property/withdrawal/success',
  AddressManagement = '/property/withdrawal/management',
  Record = '/property/records',
  Account = '/account',
  EnableTfa = '/account/enable-tfa',
  KycVerification = '/account/kyc-verification',
  Report = '/report',
  AgentRebate = '/agent-rebate',
  Support = '/support',
  ContactUs = '/support/contact-us',
  CreateAccount = '/account/create-account',
  Announcement = '/announcement',
  SignOut = '/auth/logout',
  TradingConfirm = '/trade/trading/confirm',
  OrderAll = '/order/all',
  UserAsk = '/user-ask',
  UserBid = '/user-bid',
  UserAdmin = '/user-admin',
  Home = '/home',
  Fill = '/fill',
  Setting = '/setting',
  DepositRecord = '/deposit',
  WithdrawalRecord = '/withdrawal',
  Bank = '/bank',
  Message = '/message',
  Balance = '/balance',
  Kyc = '/kyc',
}

// for seller and buyer site which has sideMenu & navbar
export const PrivatePage = [
  Page.Trade,
  Page.Order,
  Page.Property,
  Page.Account,
  Page.EnableTfa,
  Page.Report,
  Page.Support,
  Page.Announcement,
];

// for which has publicBar
export const PageWithPublicBar = [
  Page.SignIn,
  Page.Register,
  Page.ForgetPassword,
  Page.Verification,
];

export const PageStrictlyInDark = [
  Page.Landing,
  Page.SignIn,
  Page.Register,
  Page.ForgetPassword,
  Page.Verification,
];
