import { format } from 'date-fns';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import MenuLanguage from 'src/component/MenuLanguage';
import Body from 'src/component/typography/Body';
import { Page } from 'src/constant/Page';
import IcArrowDown from 'src/image/ic-arrow-down.svg';

const Footer = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="px-[15px] pb-[70px] pt-0">
      <Divider />
      <div className="mt-[40px] flex w-full flex-col gap-[20px] xs:flex-row">
        <div className="flex h-[36px] flex-1 flex-row items-center">
          <div className="flex cursor-pointer items-center" onClick={() => setOpen(true)} ref={ref}>
            {t('language')}
            <img src={IcArrowDown} />
          </div>
        </div>
        <div className="flex h-[36px] flex-1 flex-row items-center">
          <Button appearance="text" onClick={() => navigate(Page.TermsOfUse)}>
            {t('footer.terms')}
          </Button>
        </div>
        <div className="flex h-[36px] flex-1 flex-row items-center">
          <Button appearance="text" onClick={() => navigate(Page.Privacy)}>
            {t('footer.privacyPolicy')}
          </Button>
        </div>
      </div>
      <Body size="m" className="mt-[60px] w-full text-center text-text-neutral">
        Copyright {format(Date.now(), 'yyyy')} ©
      </Body>
      <MenuLanguage open={open} anchorEl={ref.current} onClose={() => setOpen(false)} />
    </div>
  );
};

export default Footer;
