import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Pagination from 'src/component/Pagination';
import RoundSelect from 'src/component/RoundSelect';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import H3 from 'src/component/typography/H3';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { usePagination } from 'src/hook/usePagination';
import { BidOrder } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getBidOrders } from 'src/service/orderService';
import Card from './component/Card';
import Table from './component/Table';

const OrderBidPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderBid: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);
  const { IcEmpty } = useContext(ThemeContext).image;
  const navigate = useNavigate();
  const [filterSorting, setFilterSorting] = useState<string>(filter.sorting ?? 'default');
  const [orderQuote, setOrderQuote] = useState<string | undefined>(filter.quote);
  const { limit, offset, setCount, paginationProps } = usePagination({ defaultPage: filter.page });
  const [orderArray, setOrderArray] = useState<Array<BidOrder>>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);

  useEffect(() => {
    getBidOrders({ quote: orderQuote, sorting: filterSorting }, { limit, offset })
      .then((res) => {
        setOrderArray(res.data);
        setCount(res.count);
      })
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [refresh, orderQuote, filterSorting, limit, offset]);

  const onQuoteFilterChange = (value: string) => {
    setOrderQuote(value !== 'all' ? value : undefined);
    paginationProps.setPage(1);
  };

  const onSortingFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterSorting(value);
  };

  return (
    <div>
      <BackButton />
      <H3 className="mb-[15px] mt-[10px] flex flex-row items-start justify-between sm:mt-[20px]">
        {t('order.heading')}
        <Button appearance="text" onClick={() => navigate(Page.OrderHistory)}>
          {t('order.act.history')}
        </Button>
      </H3>
      <div className="text-end">
        <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
          {t('order.act.refresh')}
        </Button>
      </div>
      {orderArray && (
        <>
          <div className="mt-[15px] rounded-[12px] bg-bg-content px-[15px] pb-[25px] pt-[10px] xs:px-[40px] xs:pb-[25px] xs:pt-[10px]">
            <div className="flex flex-wrap justify-between gap-[15px] px-[0px] py-[15px]">
              <RoundSelect
                label={t('order.desc.fiat')}
                defaultValue={filter.quote ?? 'all'}
                onChange={onQuoteFilterChange}
              >
                {['all', ...fiat.map((v) => v.id)].map((v) => (
                  <SelectOption key={v} value={v}>
                    {v === 'all' ? t('order.desc.all') : v.toUpperCase()}
                  </SelectOption>
                ))}
              </RoundSelect>
              <SortingSelect
                defaultValue={filter.sorting ?? 'default'}
                onChange={onSortingFilterChange}
              >
                <SelectOption value="default">{t('order.desc.sortingOption.default')}</SelectOption>
                <SelectOption value="-price">
                  {t('order.desc.sortingOption.priceDesc')}
                </SelectOption>
                <SelectOption value="price">{t('order.desc.sortingOption.priceAsce')}</SelectOption>
                <SelectOption value="-sent_amount">
                  {t('order.desc.sortingOption.sentAmountDesc')}
                </SelectOption>
                <SelectOption value="sent_amount">
                  {t('order.desc.sortingOption.sentAmountAsce')}
                </SelectOption>
                <SelectOption value="-sent_total">
                  {t('order.desc.sortingOption.sentTotalDesc')}
                </SelectOption>
                <SelectOption value="sent_total">
                  {t('order.desc.sortingOption.sentTotalAsce')}
                </SelectOption>
                <SelectOption value="-filled_amount">
                  {t('order.desc.sortingOption.filledAmountDesc')}
                </SelectOption>
                <SelectOption value="filled_amount">
                  {t('order.desc.sortingOption.filledAmountAsce')}
                </SelectOption>
                <SelectOption value="-filled_total">
                  {t('order.desc.sortingOption.filledTotalDesc')}
                </SelectOption>
                <SelectOption value="filled_total">
                  {t('order.desc.sortingOption.filledTotalAsce')}
                </SelectOption>
              </SortingSelect>
            </div>
            <Divider theme="sub" />
            {orderArray.length === 0 && (
              <div className="text-center">
                <img src={IcEmpty} />
              </div>
            )}
            {orderArray.length > 0 &&
              (isBiggerThanMd ? <Table data={orderArray} /> : <Card data={orderArray} />)}
          </div>
          <div className="mt-[20px] flex flex-row-reverse">
            <Pagination {...paginationProps} />
          </div>
        </>
      )}
    </div>
  );
};

export default OrderBidPage;
