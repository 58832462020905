import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from 'src/component/BackButton';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { Ui } from 'src/constant/Env';
import { ThemeContext } from 'src/context/ThemeContext';
import IcContactTelegram from 'src/image/ic-contact-telegram.svg';

const ContactUs = () => {
  const { t } = useTranslation();
  const { IcContactEmail } = useContext(ThemeContext).image;

  return (
    <div>
      <BackButton />
      <H3 className="mt-[10px] sm:mt-[20px]">{t('contactUs.heading')}</H3>
      <div className="mt-[20px] flex flex-col">
        <div className="mb-[20px] flex flex-row items-center rounded-[4px]">
          <img className="h-[32px] w-[32px]" src={IcContactEmail} />
          <div className="ml-[25px] flex flex-col">
            <Body className="text-text-secondary">{t('contactUs.desc.email')}</Body>
            <a
              className="text-[16px] text-text-accent no-underline"
              // href={
              //   Ui === 'seller' ? 'mailto:{{DORAD_MAIL}}' : 'mailto:{{USHARE_MAIL}}'
              // }
              target="_blank"
              rel="noreferrer"
            >
              {Ui === 'seller' ? 'dorado mail' : 'ushare mail'}
            </a>
          </div>
        </div>
        <Divider className="mb-[20px]" />
        <div className="mb-[20px] flex flex-row items-center rounded-[4px]">
          <img className="h-[32px] w-[32px]" src={IcContactTelegram} />
          <div className="ml-[25px] flex flex-col">
            <Body className="text-text-secondary">{t('contactUs.desc.telegram')}</Body>
            <a
              className="text-[16px] text-text-accent no-underline"
              // href={Ui === 'seller' ? 'https://t.me/{{DORADO}}' : 'https://t.me/{{USHARE}}'}
              target="_blank"
              rel="noreferrer"
            >
              {Ui === 'seller' ? 'dorado-telegram' : 'ushare-telegram'}
            </a>
          </div>
        </div>
        <Divider className="mb-[20px]" />
      </div>
    </div>
  );
};

export default ContactUs;
