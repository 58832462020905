import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = HTMLAttributes<HTMLDivElement> & {
  bold?: boolean;
  size?: 's' | 'm' | 'l';
};

const Body = forwardRef<HTMLDivElement, Props>(
  ({ className, bold = false, size = 'l', ...props }, ref) => (
    <div
      ref={ref}
      className={twMerge(
        classNames(
          {
            ['text-xs']: size === 's',
            ['text-sm']: size === 'm',
            ['text-base']: size === 'l',
            ['font-bold']: bold,
          },
          'leading-[1.5]',
          className,
        ),
      )}
      {...props}
    />
  ),
);

export default Body;
