import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import H3 from 'src/component/typography/H3';
import { ResetPasswordForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { updatePassword } from 'src/service/accountService';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const ResetPasswordModal = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resetPasswordForm = useForm<ResetPasswordForm>();

  const onResetPasswordFormSubmit = (formData: ResetPasswordForm) => {
    if (formData.newPassword !== formData.confirmPassword) {
      resetPasswordForm.setError('confirmPassword', {}, { shouldFocus: true });

      return;
    }

    resetPasswordForm.reset();
    handleClose();
    updatePassword(formData.password, formData.newPassword)
      .then(() =>
        dispatch(
          openSnackbar({ message: t('account.desc.updateSuccessfully'), severity: 'notify' }),
        ),
      )
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form
        className="flex flex-col gap-[25px]"
        methods={resetPasswordForm}
        onSubmit={onResetPasswordFormSubmit}
      >
        <H3>{t('passwordReset.heading')}</H3>
        <FormInput
          label={t('passwordReset.desc.currentPassword')}
          name="password"
          type="password"
          required
          asterisked
        />
        <FormInput
          label={t('passwordReset.desc.newPassword')}
          name="newPassword"
          type="password"
          required
          asterisked
        />
        <FormInput
          label={t('passwordReset.desc.confirmPassword')}
          name="confirmPassword"
          type="password"
          required
          asterisked
        />
        <div className="mt-[15px] text-right">
          <Button type="submit">{t('passwordReset.act.submit')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
