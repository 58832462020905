import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BidOrder[];
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="relative flex flex-col gap-[4px] px-[0px] py-[15px]">
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.orderId')}
          </Body>
          <Body size="m">{order.id}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.date')}
          </Body>
          <Body size="m">{format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.orderAmount')}
          </Body>
          <Body size="m">{`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.filledAmount')}
          </Body>
          <Body size="m">{`${bnFormat(order.filledAmount)} ${order.base.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.sentAmount')}
          </Body>
          <Body size="m">{`${bnFormat(order.sentAmount)} ${order.base.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.unitPrice')}
          </Body>
          <Body size="m">{`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.filledTotal')}
          </Body>
          <Body size="m">{`${bnFormat(order.filledTotal)} ${order.quote.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.paidTotal')}
          </Body>
          <Body size="m">{`${bnFormat(order.sentTotal)} ${order.quote.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.awaitingPayment')}
          </Body>
          <Body size="m">{`${bn(order.filledTotal)
            .minus(bn(order.sentTotal))
            .toFormat()} ${order.quote.toUpperCase()}`}</Body>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <Body size="m" bold className="text-text-table-thead">
            {t('order.desc.status')}
          </Body>
          <Body size="m" className="text-text-success">
            {t(`order.desc.statusDisplay.${order.status}`)}
          </Body>
        </div>
        <div className="absolute bottom-[15px] right-0">
          <Button appearance="text" onClick={() => navigate(`${Page.Order}/${order.id}`)}>
            {t('order.act.detail')}
          </Button>
        </div>
      </div>
      <Divider theme="sub" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
