import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = HTMLAttributes<HTMLHeadingElement>;

const H3 = ({ className, ...props }: Props) => (
  <h3
    className={twMerge('m-0 text-[27.2px] font-bold leading-[1.3] sm:text-[32px]', className)}
    {...props}
  />
);

export default H3;
