import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import H3 from 'src/component/typography/H3';
import H5 from 'src/component/typography/H5';
import { ThemeContext } from 'src/context/ThemeContext';
import IcAuthy from 'src/image/ic-authy.svg';
import IcGAuth from 'src/image/ic-gauth.svg';

const Step1 = () => {
  const { IcGooglePlay, IcAppStore } = useContext(ThemeContext).image;
  const { t } = useTranslation();

  return (
    <div>
      <H3 className="mt-[10px] sm:mt-[20px]">{t('enableTfa.heading')}</H3>
      <H5 className="mt-[30px]">{t('enableTfa.desc.downloadInstallApp')}</H5>
      <div className="mt-[20px] flex flex-col gap-[20px] sm:flex-row sm:gap-[30px]">
        <div className="box-border basis-[50%] rounded-[12px] bg-bg-content p-[30px]">
          <div className="flex h-[60px] flex-row items-center gap-[20px]">
            <img src={IcAuthy} />
            <H5>Authy</H5>
          </div>
          <div className="mt-[20px] flex flex-row gap-[15px] sm:flex-col sm:items-center sm:gap-[30px]">
            <img
              className="h-[45px] w-fit cursor-pointer"
              src={IcGooglePlay}
              onClick={() =>
                window.open('https://play.google.com/store/apps/details?id=com.authy.authy')
              }
            />
            <img
              className="h-[45px] w-fit cursor-pointer"
              src={IcAppStore}
              onClick={() => window.open('https://apps.apple.com/app/twilio-authy/id494168017')}
            />
          </div>
        </div>
        <div className="box-border basis-[50%] rounded-[12px] bg-bg-content p-[30px]">
          <div className="flex h-[60px] flex-row items-center gap-[20px]">
            <img src={IcGAuth} />
            <H5>Google Authenticator</H5>
          </div>
          <div className="mt-[20px] flex flex-row gap-[15px] sm:flex-col sm:items-center sm:gap-[30px]">
            <img
              className="h-[45px] w-fit cursor-pointer"
              src={IcGooglePlay}
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
                )
              }
            />
            <img
              className="h-[45px] w-fit cursor-pointer"
              src={IcAppStore}
              onClick={() =>
                window.open('https://apps.apple.com/app/google-authenticator/id388497605')
              }
            />
          </div>
        </div>
      </div>
      <H5 className="mt-[40px]">{t('enableTfa.desc.haveInstalled')}</H5>
    </div>
  );
};

export default Step1;
