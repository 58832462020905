import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  appearance?: 'primary' | 'secondary' | 'outlined' | 'text';
  size?: 'large' | 'medium' | 'small';
};

const Button = ({
  appearance = 'primary',
  size = 'medium',
  className,
  children,
  disabled,
  ...props
}: Props) => (
  <button
    className={twMerge(
      classNames(
        'cursor-pointer rounded-[28px] font-bold disabled:cursor-auto',
        {
          'min-w-[131px] min-h-[50px] py-[13px] px-[40px] text-[16px]': size === 'large',
          'min-w-[104px] min-h-[40px] py-[9px] px-[30px] text-[14px]': size === 'medium',
          'min-w-[81px] min-h-[30px] py-[6px] px-[20px] text-[13px]': size === 'small',
          'text-text-button-primary-normal bg-bg-button-primary-normal hover:bg-bg-button-primary-hover active:bg-bg-button-primary-active disabled:text-text-button-primary-disabled disabled:bg-bg-button-primary-disabled':
            appearance === 'primary',
          'text-text-button-normal bg-bg-button-secondary-normal hover:bg-bg-button-secondary-hover active:bg-bg-button-secondary-active disabled:text-text-button-disabled disabled:bg-bg-button-secondary-disabled':
            appearance === 'secondary',
          'text-text-button-normal bg-transparent border border-solid border-border-button-outline-normal hover:border-transparent active:border-transparent hover:bg-bg-button-secondary-hover active:bg-bg-button-secondary-active disabled:text-text-button-disabled disabled:bg-transparent disabled:border-border-button-outline-disabled':
            appearance === 'outlined',
          'border-none': appearance !== 'outlined',
          'text-text-link-normal bg-transparent !min-w-fit !min-h-fit p-0 hover:underline disabled:no-underline active:text-text-link-active disabled:text-text-link-disabled':
            appearance === 'text',
        },
        className,
      ),
    )}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

export default Button;
