import { Popover } from '@mui/material';
import { format } from 'date-fns';
import ReactCalendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import './calendar.scss';
import IcNextIcon from 'src/image/calendar-next.svg';
import IcPreIcon from 'src/image/calendar-prev.svg';
import Body from './typography/Body';

type Props = {
  open: boolean;
  onClose: () => void;
  anchorEl?: Element | null;
  value: Date;
  onChange: (v: Date) => void;
  onClear?: () => void;
};

const Calendar = ({ open, onClose, anchorEl, value, onChange, onClear }: Props) => {
  const { t } = useTranslation();

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 40,
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          className: '!rounded-[8px]',
        },
      }}
    >
      <div className="relative box-border h-[102px] bg-bg-datepicker-primary px-[20px] py-[25px] text-text-datepicker-date-primary">
        <Body size="s">{format(value, 'yyyy')}</Body>
        <Body bold className="text-[24px]">
          {format(value, 'E, MMMM d')}
        </Body>
        {onClear && (
          <div className="absolute right-[9px] top-[6px] cursor-pointer" onClick={onClear}>
            {t('act.clear')}
          </div>
        )}
      </div>
      <ReactCalendar
        locale="en-US"
        defaultValue={value}
        onChange={onChange}
        prev2Label={null}
        prevLabel={<img src={IcPreIcon} />}
        nextLabel={<img src={IcNextIcon} className="ml-auto" />}
        next2Label={null}
      />
    </Popover>
  );
};

export default Calendar;
