import IcAccountFocusBlueDark from 'src/image/blue/ic-account-focus-dark.svg';
import IcAccountFocusBlueLight from 'src/image/blue/ic-account-focus-light.svg';
import IcAddBlueDark from 'src/image/blue/ic-add-dark.svg';
import IcAddBlueLight from 'src/image/blue/ic-add-light.svg';
import IcAgencyBlueDark from 'src/image/blue/ic-agency-dark.svg';
import IcAgencyBlueLight from 'src/image/blue/ic-agency-light.svg';
import IcAnnouncementBlueRedDark from 'src/image/blue/ic-announcement-red-dark.svg';
import IcAnnouncementBlueRedLight from 'src/image/blue/ic-announcement-red-light.svg';
import IcBackBlueDark from 'src/image/blue/ic-back-dark.svg';
import IcBackBlueLight from 'src/image/blue/ic-back-light.svg';
import IcCalendarBlueDark from 'src/image/blue/ic-calendar-dark.svg';
import IcCalendarBlueLight from 'src/image/blue/ic-calendar-light.svg';
import IcCopyBlueDark from 'src/image/blue/ic-copy-dark.svg';
import IcCopyBlueLight from 'src/image/blue/ic-copy-light.svg';
import IcEditBlueDark from 'src/image/blue/ic-edit-dark.svg';
import IcEditBlueLight from 'src/image/blue/ic-edit-light.svg';
import IcEmptyBlue from 'src/image/blue/ic-empty.svg';
import IcForwardBlueDark from 'src/image/blue/ic-forward-dark.svg';
import IcForwardBlueLight from 'src/image/blue/ic-forward-light.svg';
import IcGoMessageBlueDark from 'src/image/blue/ic-go-message-dark.svg';
import IcGoMessageBlueLight from 'src/image/blue/ic-go-message-light.svg';
import IcOrderFocusBlueDark from 'src/image/blue/ic-order-focus-dark.svg';
import IcOrderFocusBlueLight from 'src/image/blue/ic-order-focus-light.svg';
import IcPaginationNextBlueDark from 'src/image/blue/ic-pagination-next-dark.svg';
import IcPaginationNextBlueLight from 'src/image/blue/ic-pagination-next-light.svg';
import IcPaginationPrevBlueDark from 'src/image/blue/ic-pagination-prev-dark.svg';
import IcPaginationPrevBlueLight from 'src/image/blue/ic-pagination-prev-light.svg';
import IcPropertyFocusBlueDark from 'src/image/blue/ic-property-focus-dark.svg';
import IcPropertyFocusBlueLight from 'src/image/blue/ic-property-focus-light.svg';
import IcQrcodeBlueDark from 'src/image/blue/ic-qrcode-dark.svg';
import IcQrcodeBlueLight from 'src/image/blue/ic-qrcode-light.svg';
import IcRecordBlueDark from 'src/image/blue/ic-record-dark.svg';
import IcRecordBlueLight from 'src/image/blue/ic-record-light.svg';
import IcReportFocusBlueDark from 'src/image/blue/ic-report-focus-dark.svg';
import IcReportFocusBlueLight from 'src/image/blue/ic-report-focus-light.svg';
import IcSortDownBlueDark from 'src/image/blue/ic-sort-down-dark.svg';
import IcSortDownBlueLight from 'src/image/blue/ic-sort-down-light.svg';
import IcSortUpBlueDark from 'src/image/blue/ic-sort-up-dark.svg';
import IcSortUpBlueLight from 'src/image/blue/ic-sort-up-light.svg';
import IcSortingBlueDark from 'src/image/blue/ic-sorting-dark.svg';
import IcSortingBlueLight from 'src/image/blue/ic-sorting-light.svg';
import IcContactBlue from 'src/image/blue/ic-support-contact.svg';
import IcSupportFocusBlueDark from 'src/image/blue/ic-support-focus-dark.svg';
import IcSupportFocusBlueLight from 'src/image/blue/ic-support-focus-light.svg';
import IcPrivacypolicyBlue from 'src/image/blue/ic-support-privacypolicy.svg';
import IcTermofuseBlue from 'src/image/blue/ic-support-termofuse.svg';
import IcTradeFocusBlueDark from 'src/image/blue/ic-trade-focus-dark.svg';
import IcTradeFocusBlueLight from 'src/image/blue/ic-trade-focus-light.svg';
import IcUploadBlueDark from 'src/image/blue/ic-upload-dark.svg';
import IcUploadBlueLight from 'src/image/blue/ic-upload-light.svg';
import IcAccount from 'src/image/ic-account.svg';
import IcAnnouncementDark from 'src/image/ic-announcement-dark.svg';
import IcAnnouncementLight from 'src/image/ic-announcement-light.svg';
import IcAppStoreDark from 'src/image/ic-app-store-dark.svg';
import IcAppStoreLight from 'src/image/ic-app-store-light.svg';
import IcContactEmailDark from 'src/image/ic-contact-email-dark.svg';
import IcContactEmailLight from 'src/image/ic-contact-email-light.svg';
import IcGooglePlayDark from 'src/image/ic-google-play-dark.svg';
import IcGooglePlayLight from 'src/image/ic-google-play-light.svg';
import IcMenuDark from 'src/image/ic-menu-dark.svg';
import IcMenuLight from 'src/image/ic-menu-light.svg';
import IcNoticeClear from 'src/image/ic-notice-clear.svg';
import IcNoticeError from 'src/image/ic-notice-error.svg';
import IcNoticeWarning from 'src/image/ic-notice-warning.svg';
import IcOrder from 'src/image/ic-order.svg';
import IcProperty from 'src/image/ic-property.svg';
import IcReport from 'src/image/ic-report.svg';
import IcSearch from 'src/image/ic-search.svg';
import IcSortDefaultDark from 'src/image/ic-sort-default-dark.svg';
import IcSortDefaultLight from 'src/image/ic-sort-default-light.svg';
import IcSuccessDark from 'src/image/ic-success-dark.svg';
import IcSuccessLight from 'src/image/ic-success-light.svg';
import IcSupport from 'src/image/ic-support.svg';
import IcThemeDark from 'src/image/ic-theme-dark.svg';
import IcThemeLight from 'src/image/ic-theme-light.svg';
import IcTrade from 'src/image/ic-trade.svg';
import IcAccountFocusDark from 'src/image/yellow/ic-account-focus-dark.svg';
import IcAccountFocusLight from 'src/image/yellow/ic-account-focus-light.svg';
import IcAddYellowDark from 'src/image/yellow/ic-add-dark.svg';
import IcAddYellowLight from 'src/image/yellow/ic-add-light.svg';
import IcAgencyYellowDark from 'src/image/yellow/ic-agency-dark.svg';
import IcAgencyYellowFocusDark from 'src/image/yellow/ic-agency-focus-dark.svg';
import IcAgencyYellowFocusLight from 'src/image/yellow/ic-agency-focus-light.svg';
import IcAgencyYellowLight from 'src/image/yellow/ic-agency-light.svg';
import IcAnnouncementYellowRedDark from 'src/image/yellow/ic-announcement-red-dark.svg';
import IcAnnouncementYellowRedLight from 'src/image/yellow/ic-announcement-red-light.svg';
import IcBackYellowDark from 'src/image/yellow/ic-back-dark.svg';
import IcBackYellowLight from 'src/image/yellow/ic-back-light.svg';
import IcCalendarYellowDark from 'src/image/yellow/ic-calendar-dark.svg';
import IcCalendarYellowLight from 'src/image/yellow/ic-calendar-light.svg';
import IcCopyYellowDark from 'src/image/yellow/ic-copy-dark.svg';
import IcCopyYellowLight from 'src/image/yellow/ic-copy-light.svg';
import IcEditYellowDark from 'src/image/yellow/ic-edit-dark.svg';
import IcEditYellowLight from 'src/image/yellow/ic-edit-light.svg';
import IcEmptyYellow from 'src/image/yellow/ic-empty.svg';
import IcForwardYellowDark from 'src/image/yellow/ic-forward-dark.svg';
import IcForwardYellowLight from 'src/image/yellow/ic-forward-light.svg';
import IcGoMessageYellowDark from 'src/image/yellow/ic-go-message-dark.svg';
import IcGoMessageYellowLight from 'src/image/yellow/ic-go-message-light.svg';
import IcOrderFocusYellowDark from 'src/image/yellow/ic-order-focus-dark.svg';
import IcOrderFocusYellowLight from 'src/image/yellow/ic-order-focus-light.svg';
import IcPaginationNextYellowDark from 'src/image/yellow/ic-pagination-next-dark.svg';
import IcPaginationNextYellowLight from 'src/image/yellow/ic-pagination-next-light.svg';
import IcPaginationPrevYellowDark from 'src/image/yellow/ic-pagination-prev-dark.svg';
import IcPaginationPrevYellowLight from 'src/image/yellow/ic-pagination-prev-light.svg';
import IcPropertyFocusYellowDark from 'src/image/yellow/ic-property-focus-dark.svg';
import IcPropertyFocusYellowLight from 'src/image/yellow/ic-property-focus-light.svg';
import IcQrcodeYellowDark from 'src/image/yellow/ic-qrcode-dark.svg';
import IcQrcodeYellowLight from 'src/image/yellow/ic-qrcode-light.svg';
import IcRecordYellowDark from 'src/image/yellow/ic-record-dark.svg';
import IcRecordYellowLight from 'src/image/yellow/ic-record-light.svg';
import IcReportFocusYellowDark from 'src/image/yellow/ic-report-focus-dark.svg';
import IcReportFocusYellowLight from 'src/image/yellow/ic-report-focus-light.svg';
import IcSortDownYellowDark from 'src/image/yellow/ic-sort-down-dark.svg';
import IcSortDownYellowLight from 'src/image/yellow/ic-sort-down-light.svg';
import IcSortUpYellowDark from 'src/image/yellow/ic-sort-up-dark.svg';
import IcSortUpYellowLight from 'src/image/yellow/ic-sort-up-light.svg';
import IcSortingYellowDark from 'src/image/yellow/ic-sorting-dark.svg';
import IcSortingYellowLight from 'src/image/yellow/ic-sorting-light.svg';
import IcContactYellow from 'src/image/yellow/ic-support-contact.svg';
import IcSupportFocusYellowDark from 'src/image/yellow/ic-support-focus-dark.svg';
import IcSupportFocusYellowLight from 'src/image/yellow/ic-support-focus-light.svg';
import IcPrivacypolicyYellow from 'src/image/yellow/ic-support-privacypolicy.svg';
import IcTermofuseYellow from 'src/image/yellow/ic-support-termofuse.svg';
import IcTradeFocusYellowDark from 'src/image/yellow/ic-trade-focus-dark.svg';
import IcTradeFocusYellowLight from 'src/image/yellow/ic-trade-focus-light.svg';
import IcUploadYellowDark from 'src/image/yellow/ic-upload-dark.svg';
import IcUploadYellowLight from 'src/image/yellow/ic-upload-light.svg';

export enum Theme {
  YellowDark = 'yellow-dark',
  YellowLight = 'yellow-light',
  BlueDark = 'blue-dark',
  BlueLight = 'blue-light',
}

const CommonAll = {
  IcTrade,
  IcOrder,
  IcProperty,
  IcReport,
  IcAccount,
  IcSupport,
  IcSearch,
  IcNoticeClear,
  IcNoticeWarning,
  IcNoticeError,
};

const CommonLight = {
  IcAnnouncement: IcAnnouncementLight,
  IcAppStore: IcAppStoreLight,
  IcContactEmail: IcContactEmailLight,
  IcGooglePlay: IcGooglePlayLight,
  IcMenu: IcMenuLight,
  IcSortDefault: IcSortDefaultLight,
};

const CommonDark = {
  IcAnnouncement: IcAnnouncementDark,
  IcAppStore: IcAppStoreDark,
  IcContactEmail: IcContactEmailDark,
  IcGooglePlay: IcGooglePlayDark,
  IcMenu: IcMenuDark,
  IcSortDefault: IcSortDefaultDark,
};

const CommonYellow = {
  IcEmpty: IcEmptyYellow,
  IcContact: IcContactYellow,
  IcPrivacypolicy: IcPrivacypolicyYellow,
  IcTermofuse: IcTermofuseYellow,
};

const CommonBlue = {
  IcEmpty: IcEmptyBlue,
  IcContact: IcContactBlue,
  IcPrivacypolicy: IcPrivacypolicyBlue,
  IcTermofuse: IcTermofuseBlue,
};

export const Image = {
  [Theme.YellowLight]: {
    ...CommonAll,
    ...CommonLight,
    ...CommonYellow,
    IcAdd: IcAddYellowLight,
    IcAnnouncementRed: IcAnnouncementYellowRedLight,
    IcTheme: IcThemeLight,
    IcAccountFocus: IcAccountFocusLight,
    IcOrderFocus: IcOrderFocusYellowLight,
    IcPropertyFocus: IcPropertyFocusYellowLight,
    IcSupportFocus: IcSupportFocusYellowLight,
    IcTradeFocus: IcTradeFocusYellowLight,
    IcAgency: IcAgencyYellowLight,
    IcAgencyFocus: IcAgencyYellowFocusLight,
    IcReportFocus: IcReportFocusYellowLight,
    IcBack: IcBackYellowLight,
    IcForward: IcForwardYellowLight,
    IcCopy: IcCopyYellowLight,
    IcEdit: IcEditYellowLight,
    IcPaginationNext: IcPaginationNextYellowLight,
    IcPaginationPrev: IcPaginationPrevYellowLight,
    IcSuccess: IcSuccessLight,
    IcRecord: IcRecordYellowLight,
    IcCalendar: IcCalendarYellowLight,
    IcGoMessage: IcGoMessageYellowLight,
    IcQrcode: IcQrcodeYellowLight,
    IcSorting: IcSortingYellowLight,
    IcSortUp: IcSortUpYellowLight,
    IcSortDown: IcSortDownYellowLight,
    IcUpload: IcUploadYellowLight,
  },
  [Theme.YellowDark]: {
    ...CommonAll,
    ...CommonDark,
    ...CommonYellow,
    IcAdd: IcAddYellowDark,
    IcAnnouncementRed: IcAnnouncementYellowRedDark,
    IcTheme: IcThemeDark,
    IcAccountFocus: IcAccountFocusDark,
    IcOrderFocus: IcOrderFocusYellowDark,
    IcPropertyFocus: IcPropertyFocusYellowDark,
    IcSupportFocus: IcSupportFocusYellowDark,
    IcTradeFocus: IcTradeFocusYellowDark,
    IcAgency: IcAgencyYellowDark,
    IcAgencyFocus: IcAgencyYellowFocusDark,
    IcReportFocus: IcReportFocusYellowDark,
    IcBack: IcBackYellowDark,
    IcForward: IcForwardYellowDark,
    IcCopy: IcCopyYellowDark,
    IcEdit: IcEditYellowDark,
    IcPaginationNext: IcPaginationNextYellowDark,
    IcPaginationPrev: IcPaginationPrevYellowDark,
    IcSuccess: IcSuccessDark,
    IcRecord: IcRecordYellowDark,
    IcCalendar: IcCalendarYellowDark,
    IcGoMessage: IcGoMessageYellowDark,
    IcQrcode: IcQrcodeYellowDark,
    IcSorting: IcSortingYellowDark,
    IcSortUp: IcSortUpYellowDark,
    IcSortDown: IcSortDownYellowDark,
    IcUpload: IcUploadYellowDark,
  },
  [Theme.BlueLight]: {
    ...CommonAll,
    ...CommonLight,
    ...CommonBlue,
    IcAdd: IcAddBlueLight,
    IcAnnouncementRed: IcAnnouncementBlueRedLight,
    IcTheme: IcThemeLight,
    IcAccountFocus: IcAccountFocusBlueLight,
    IcOrderFocus: IcOrderFocusBlueLight,
    IcPropertyFocus: IcPropertyFocusBlueLight,
    IcSupportFocus: IcSupportFocusBlueLight,
    IcTradeFocus: IcTradeFocusBlueLight,
    IcAgency: IcAgencyBlueLight,
    IcAgencyFocus: IcAgencyBlueLight,
    IcReportFocus: IcReportFocusBlueLight,
    IcBack: IcBackBlueLight,
    IcForward: IcForwardBlueLight,
    IcCopy: IcCopyBlueLight,
    IcEdit: IcEditBlueLight,
    IcPaginationNext: IcPaginationNextBlueLight,
    IcPaginationPrev: IcPaginationPrevBlueLight,
    IcSuccess: IcSuccessLight,
    IcRecord: IcRecordBlueLight,
    IcCalendar: IcCalendarBlueLight,
    IcGoMessage: IcGoMessageBlueLight,
    IcQrcode: IcQrcodeBlueLight,
    IcSorting: IcSortingBlueLight,
    IcSortUp: IcSortUpBlueLight,
    IcSortDown: IcSortDownBlueLight,
    IcUpload: IcUploadBlueLight,
  },
  [Theme.BlueDark]: {
    ...CommonAll,
    ...CommonDark,
    ...CommonBlue,
    IcAdd: IcAddBlueDark,
    IcAnnouncementRed: IcAnnouncementBlueRedDark,
    IcTheme: IcThemeDark,
    IcAccountFocus: IcAccountFocusBlueDark,
    IcOrderFocus: IcOrderFocusBlueDark,
    IcPropertyFocus: IcPropertyFocusBlueDark,
    IcSupportFocus: IcSupportFocusBlueDark,
    IcTradeFocus: IcTradeFocusBlueDark,
    IcAgency: IcAgencyBlueDark,
    IcAgencyFocus: IcAgencyBlueDark,
    IcReportFocus: IcReportFocusBlueDark,
    IcBack: IcBackBlueDark,
    IcForward: IcForwardBlueDark,
    IcCopy: IcCopyBlueDark,
    IcEdit: IcEditBlueDark,
    IcPaginationNext: IcPaginationNextBlueDark,
    IcPaginationPrev: IcPaginationPrevBlueDark,
    IcSuccess: IcSuccessDark,
    IcRecord: IcRecordBlueDark,
    IcCalendar: IcCalendarBlueDark,
    IcGoMessage: IcGoMessageBlueDark,
    IcQrcode: IcQrcodeBlueDark,
    IcSorting: IcSortingBlueDark,
    IcSortUp: IcSortUpBlueDark,
    IcSortDown: IcSortDownBlueDark,
    IcUpload: IcUploadBlueDark,
  },
};
