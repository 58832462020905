import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { Contact } from 'src/model/Contact';

type Props = {
  contactList: Contact[];
  onDelete: (id: string) => void;
};

const Card = ({ contactList, onDelete }: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (contact: Contact, index: number, array: Contact[]) => (
    <Body size="m" key={contact.id}>
      <div className="py-[16px]">
        <div>{contact.coin.toUpperCase()}</div>
        <div className="break-words">{contact.label}</div>
        <div className="break-words">
          {contact.address}
          <span className="ml-[10px] text-text-warning">{contact.network?.toUpperCase()}</span>
        </div>
        <div className="h-[21px] text-end">
          <Button
            appearance="text"
            className="text-text-alert"
            onClick={() => onDelete(contact.id)}
          >
            {t('addressManagement.act.delete')}
          </Button>
        </div>
      </div>
      <Divider
        theme="sub"
        className={classNames('mt-[16px]', {
          hidden: index >= array.length - 1,
        })}
      />
    </Body>
  );

  return <div className="px-[15px] pb-[9px] pt-[0px]">{contactList.map(elementMapFunction)}</div>;
};

export default Card;
