import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import kycEndpoint from 'src/api/kycEndpoint';
import meEndpoint from 'src/api/meEndpoint';
import userEndpoint from 'src/api/userEndpoint';
import { AccountUser } from 'src/model/AccountUser';
import { KycForm } from 'src/model/Form';
import { Kyc } from 'src/model/Kyc';
import { setAccountInfo } from 'src/redux/authSlice';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { file2Base64 } from 'src/util/fileConverter';
import { getPage } from 'src/util/paginationHelper';

export const uploadKycs = async (formData: KycForm, files: File[]) => {
  try {
    dispatch(startWaiting());
    for (const f of files)
      if (f.size > 16 * 1024 * 1024) throw t('kycVerification.desc.fileTooLarge');
    await kycEndpoint.postKycs({
      ...formData,
      files: await Promise.all(
        files.map(async (v) => ({
          content: await file2Base64(v),
        })),
      ),
    });
    const ownInfoRes = await meEndpoint.getOwnInfo();
    dispatch(setAccountInfo(ownInfoRes.data));
  } finally {
    dispatch(finishWaiting());
  }
};

export const getKycList = async (status: string, offset: number, limit: number) => {
  try {
    dispatch(startWaiting());
    const res = await kycEndpoint.getKycs({ status, offset, limit });

    dispatch(
      updateFilter({
        key: 'adminKyc',
        filter: {
          status,
          page: getPage(offset, limit),
        },
      }),
    );

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getKycById = async (id: string, userId: string | null) => {
  try {
    dispatch(startWaiting());
    let resKyc: AxiosResponse<Kyc>;
    let resUser: AxiosResponse<AccountUser>;
    if (userId === null) {
      resKyc = await kycEndpoint.getKycsId(id);
      resUser = await userEndpoint.getTheUser(resKyc.data.userId);
    } else
      [resKyc, resUser] = await Promise.all([
        kycEndpoint.getKycsId(id),
        userEndpoint.getTheUser(userId),
      ]);

    return { kyc: resKyc.data, user: resUser.data };
  } finally {
    dispatch(finishWaiting());
  }
};

export const passKyc = async (id: string, comment: string) => {
  try {
    dispatch(startWaiting());
    const res = await kycEndpoint.patchKycsIdPass(id, {
      comment: comment.length > 0 ? comment : undefined,
    });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const rejectKyc = async (id: string, comment: string) => {
  try {
    dispatch(startWaiting());
    const res = await kycEndpoint.patchKycsIdFail(id, {
      comment: comment.length > 0 ? comment : undefined,
    });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
