import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Divider from 'src/component/Divider';
import Pagination from 'src/component/Pagination';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { ThemeContext } from 'src/context/ThemeContext';
import { usePagination } from 'src/hook/usePagination';
import { Announcement as AnnouncementData } from 'src/model/Announcement';
import { openSnackbar } from 'src/redux/uiSlice';
import { getAnnouncementList } from 'src/service/announcementService';

const Announcement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcEmpty } = useContext(ThemeContext).image;
  const navigate = useNavigate();
  const [announcementList, setAnnouncementList] = useState<AnnouncementData[]>([]);
  const { limit, offset, setCount, paginationProps } = usePagination();

  useEffect(() => {
    getAnnouncementList({ offset, limit })
      .then((res) => {
        setAnnouncementList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  const onClick = (id: string) => () => {
    navigate(id);
  };

  return (
    <div>
      <BackButton />
      <H3 className="mt-[10px] sm:mt-[20px]">{t('announcement.heading')}</H3>
      {announcementList.length === 0 && (
        <Body size="l" className="mt-[70px] flex flex-col items-center sm:mt-[140px]">
          <img src={IcEmpty} />
          {t('desc.noRecord')}
        </Body>
      )}
      {announcementList.length > 0 && (
        <div>
          <div className="mt-[16px] rounded-[12px] bg-bg-content">
            {announcementList.map((v, i) => (
              <div key={v.id}>
                <div
                  className="flex cursor-pointer flex-col gap-[16px] p-[16px]"
                  onClick={onClick(v.id)}
                >
                  <div className="flex items-center gap-[10px]">
                    {v.readAt === null && (
                      <div className="h-[6px] w-[6px] rounded-full bg-text-alert" />
                    )}
                    <div>{v.subject}</div>
                  </div>
                  <div>{format(Date.parse(v.publishedAt), 'yyyy-MM-dd HH:mm')}</div>
                </div>
                {announcementList.length - 1 > i && <Divider theme="sub" className="mx-[16px]" />}
              </div>
            ))}
          </div>
          <div className="mt-[15px] flex flex-row-reverse">
            <Pagination {...paginationProps} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Announcement;
