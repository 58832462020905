import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import { ThemeContext } from 'src/context/ThemeContext';
import { PageForm } from 'src/model/Form';
import Body from './typography/Body';

type Props = {
  page: number;
  pageCount: number;
  setPage?: (v: number) => void;
};

const Pagination = ({ page, pageCount, setPage }: Props) => {
  const [error, setError] = useState<boolean>(false);
  const { IcPaginationNext, IcPaginationPrev } = useContext(ThemeContext).image;
  const methods = useForm<PageForm>();
  useEffect(() => {
    methods.setValue('page', page.toString());
  }, [page]);

  const isValidPage = (v: number) => v >= 1 && v <= pageCount;

  const onPageChange = (v: number) => {
    if (!isValidPage(v)) return;
    setPage && setPage(v);
  };

  const onSubmit = (data: PageForm) => {
    const inputPage = Number(data.page);
    if (isValidPage(inputPage)) {
      setError(false);
      setPage && setPage(inputPage);
    } else setError(true);
  };

  return (
    <div className="flex h-[30px] w-min items-center gap-[10px]">
      <div className="h-[30px] w-[20px] cursor-pointer" onClick={() => onPageChange(page - 1)}>
        <img src={IcPaginationPrev} />
      </div>
      <Form methods={methods} onSubmit={onSubmit}>
        <FormInput
          name="page"
          defaultValue={page}
          className={twMerge(
            classNames(
              'h-[30px] w-[44px] rounded-[4px] border-0 bg-bg-displayfield px-[8px] py-[3px]',
              {
                'border-[1px] border-border-input-error focus:border-border-input-error': error,
              },
            ),
          )}
          regex={new RegExp('^\\d*$')}
        />
      </Form>
      <Body>/</Body>
      <Body>{pageCount < 1 ? '-' : pageCount}</Body>
      <div className="h-[30px] w-[20px] cursor-pointer" onClick={() => onPageChange(page + 1)}>
        <img src={IcPaginationNext} />
      </div>
    </div>
  );
};

export default Pagination;
