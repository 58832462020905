import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BidOrder[];
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="flex flex-row">
        <Body
          size="m"
          className="box-border flex w-1/12 flex-col gap-[8px] break-all py-[16px] pl-[0px] pr-[12px]"
        >
          {order.id}
        </Body>
        <Body
          size="m"
          className="box-border flex w-1/6 flex-col gap-[8px] break-all py-[16px] pl-[0px] pr-[12px]"
        >
          {format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </Body>
        <div className="box-border flex w-1/6 flex-col gap-[8px] break-all py-[16px] pl-[0px] pr-[12px]">
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('order.desc.orderAmount')}
            </Body>
            <Body size="m">
              <span>{`${bnFormat(order.amount)} `}</span>
              <span className="break-keep">{order.base.toUpperCase()}</span>
            </Body>
          </div>
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('order.desc.filledAmount')}
            </Body>
            <Body size="m">
              <span>{`${bnFormat(order.filledAmount)} `}</span>
              <span className="break-keep">{order.base.toUpperCase()}</span>
            </Body>
          </div>
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('order.desc.sentAmount')}
            </Body>
            <Body size="m">
              <span>{`${bnFormat(order.sentAmount)} `}</span>
              <span className="break-keep">{order.base.toUpperCase()}</span>
            </Body>
          </div>
        </div>
        <Body
          size="m"
          className="box-border flex w-1/12 flex-col gap-[8px] break-all py-[16px] pl-[0px] pr-[12px]"
        >
          {`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}
        </Body>
        <div className="box-border flex w-1/6 flex-col gap-[8px] break-all py-[16px] pl-[0px] pr-[12px]">
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('order.desc.filledTotal')}
            </Body>
            <Body size="m">
              <span>{`${bnFormat(order.filledTotal)} `}</span>
              <span className="break-keep">{order.quote.toUpperCase()}</span>
            </Body>
          </div>
          <div>
            <Body size="m" bold className="text-text-table-thead">
              {t('order.desc.paidTotal')}
            </Body>
            <Body size="m">
              <span>{`${bnFormat(order.sentTotal)} `}</span>
              <span className="break-keep">{order.quote.toUpperCase()}</span>
            </Body>
          </div>
        </div>
        <Body
          size="m"
          className="box-border flex w-1/6 flex-col gap-[8px] break-all py-[16px] pl-[0px] pr-[12px]"
        >
          {`${bn(order.filledTotal)
            .minus(bn(order.sentTotal))
            .toFormat()} ${order.quote.toUpperCase()}`}
        </Body>
        <Body
          size="m"
          className="box-border flex w-1/12 flex-col gap-[8px] break-all py-[16px] pl-[0px] pr-[12px] text-text-success"
        >
          {t(`order.desc.statusDisplay.${order.status}`)}
        </Body>
        <div className="box-border flex w-1/12 flex-col gap-[8px] break-all py-[16px] pl-[0px] pr-[12px]">
          <Button appearance="text" onClick={() => navigate(`${Page.Order}/${order.id}`)}>
            {t('order.act.detail')}
          </Button>
        </div>
      </div>
      <Divider theme="sub" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <Body
          size="m"
          bold
          className="box-border flex w-1/12 flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('order.desc.orderId')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/6 flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('order.desc.date')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/6 flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('order.desc.amount')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/12 flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('order.desc.unitPrice')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/6 flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('order.desc.total')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/6 flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('order.desc.awaitingPayment')}
        </Body>
        <Body
          size="m"
          bold
          className="box-border flex w-1/12 flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-text-table-thead"
        >
          {t('order.desc.status')}
        </Body>
        <div className="box-border flex w-1/12 flex-col gap-[8px] py-[16px] pl-[0px] pr-[12px] text-text-table-thead" />
      </div>
      <Divider theme="sub" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default Table;
