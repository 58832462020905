import { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { Ui } from 'src/constant/Env';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import useQuery from 'src/hook/useQuery';
import { Address } from 'src/model/Deposit';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getAddress } from 'src/service/propertyService';

type Query = { coinId?: string; network?: string };

const Deposit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcCopy, IcRecord } = useContext(ThemeContext).image;
  const navigate = useNavigate();
  const query = useQuery<Query>();
  const location = useLocation();
  const state = location.state as { userId: string } | null;
  const [address, setAddress] = useState<Address>();
  const [selectCoin, setSelectCoin] = useState<string>();
  const [selectNetwork, setSelectNetwork] = useState<string>();
  const { crypto } = useSelector((rootState: RootState) => rootState.coin);

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  useEffect(() => {
    if (crypto.length === 0 || !selectCoin) return;
    getAddress({
      coin: selectCoin,
      network: selectNetwork,
      user: state?.userId,
    })
      .then((res) => setAddress(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [crypto, selectCoin, selectNetwork]);

  const onCoinChange = (coin: string) => {
    setSelectCoin(coin);
    if (coin === 'usdt') setSelectNetwork('trc20');
    else setSelectNetwork(undefined);
  };

  const onNetworkChange = (network: string) => {
    setSelectNetwork(network);
  };

  const coin = crypto.find((item) => item.id === selectCoin);

  useEffect(() => {
    if (crypto.length === 0) return;

    let currentCoin = 'usdt';
    if (query.coinId && crypto.map((v) => v.id).includes(query.coinId.toLowerCase())) {
      setSelectCoin(query.coinId.toLowerCase());
      currentCoin = query.coinId.toLowerCase();
    } else setSelectCoin('usdt');

    const currentCrypto = crypto.find((v) => v.id === currentCoin);
    if (currentCrypto?.networks.length === 1 && currentCrypto.networks[0].network === '')
      setSelectNetwork(undefined);
    else if (
      query.network &&
      currentCrypto?.networks.map((v) => v.network).includes(query.network.toLowerCase())
    )
      setSelectNetwork(query.network.toLowerCase());
    else setSelectNetwork('trc20');
  }, [crypto, query]);

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] flex flex-col items-start xs:flex-row xs:justify-between xs:justify-items-end sm:mt-[20px]">
        <H3>
          {state === null
            ? t('deposit.heading')
            : t('deposit.headingOfUser', { userId: state.userId })}
        </H3>
        {Ui !== 'admin' && (
          <Button
            appearance="text"
            className="mt-[20px] flex flex-row items-center xs:mt-[0px]"
            onClick={() => navigate(Page.Record)}
          >
            <img src={IcRecord} />
            {t('deposit.act.records')}
          </Button>
        )}
      </div>
      {crypto.length !== 0 && selectCoin && (
        <div className="mx-auto my-0 mt-[30px] max-w-[560px]">
          <Select
            label={t('deposit.desc.coin')}
            defaultValue={selectCoin.toLowerCase()}
            onChange={onCoinChange}
            asterisked
          >
            {crypto.map((value) => (
              <SelectOption key={value.id} value={value.id}>
                {value.id.toUpperCase()}
              </SelectOption>
            ))}
          </Select>
          {selectNetwork && (
            <div className="mt-[20px]">
              <Select
                label={t('deposit.desc.selectNetwork')}
                defaultValue={selectNetwork.toLowerCase()}
                onChange={onNetworkChange}
                asterisked
              >
                {coin?.networks.map((value) => (
                  <SelectOption key={value.network} value={value.network}>
                    {value.network.toUpperCase()}
                  </SelectOption>
                ))}
              </Select>
            </div>
          )}
          <div className="mx-auto my-[40px] h-[180px] w-[180px] bg-white p-[10px]">
            {address?.address && <QRCode value={address.address} size={180} />}
          </div>
          <Body className="mx-auto my-0 flex w-fit flex-row items-center gap-[10px] rounded-[12px] bg-bg-content p-[15px] py-[6px]">
            {address?.address ?? ''}
            <CopyToClipboard onCopy={onCopy} text={address?.address ?? ''}>
              <img className="cursor-pointer" src={IcCopy} />
            </CopyToClipboard>
          </Body>
          <ul className="mt-[40px] text-text-list">
            {t<string, string[]>('deposit.desc.noticeItems', {
              returnObjects: true,
              defaultValue: [],
              coinType: 'USDT',
            }).map((value, index) => (
              <li key={`notice${index}`} className="pb-[10px]">
                <Body size="m">{value}</Body>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Deposit;
