import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import Body from './typography/Body';

type Props = HTMLAttributes<HTMLDivElement> & { focus?: boolean };

const ListItem = ({ children, focus, className, ...props }: Props) => (
  <Body
    className={twMerge(
      `flex cursor-pointer items-center bg-bg-surface-overlay px-[15px] py-[10px] 
      text-text-body hover:bg-bg-surface-overlay-hover`,
      classNames({ 'text-text-link-normal': focus }),
      className,
    )}
    size="m"
    {...props}
  >
    {children}
  </Body>
);

export default ListItem;
