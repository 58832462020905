import {
  GetKycsIdResponse,
  GetKycsParams,
  GetKycsResponse,
  PatchKycsIdFailRequest,
  PatchKycsIdFailResponse,
  PatchKycsIdPassRequest,
  PatchKycsIdPassResponse,
  PostKycsRequest,
  PostKycsResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getKycs = async (params?: GetKycsParams) => {
  try {
    return await http.authGet<GetKycsResponse, GetKycsParams>('kycs', {
      params,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getKycsId = async (id: string) => {
  try {
    return await http.authGet<GetKycsIdResponse>(`kycs/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const postKycs = async (data: PostKycsRequest) => {
  try {
    return await http.authPost<PostKycsResponse, PostKycsRequest>('kycs', {
      data,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchKycsIdPass = async (id: string, data: PatchKycsIdPassRequest) => {
  try {
    return await http.authPatch<PatchKycsIdPassResponse, PatchKycsIdPassRequest>(
      `kycs/${id}/pass`,
      {
        data,
      },
    );
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchKycsIdFail = async (id: string, data: PatchKycsIdFailRequest) => {
  try {
    return await http.authPatch<PatchKycsIdFailResponse, PatchKycsIdFailRequest>(
      `kycs/${id}/fail`,
      {
        data,
      },
    );
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getKycs,
  getKycsId,
  postKycs,
  patchKycsIdPass,
  patchKycsIdFail,
};
