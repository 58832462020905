import { format } from 'date-fns';
import { useCallback, useContext, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Image from 'src/component/Image';
import Body from 'src/component/typography/Body';
import { ThemeContext } from 'src/context/ThemeContext';
import { Fill } from 'src/model/Order';
import { openSnackbar } from 'src/redux/uiSlice';
import { unsendFill, uploadReceipt } from 'src/service/orderService';
import { bnFormat } from 'src/util/bigNumber';
import UnsendModal from './UnsendModal';
import UploadModal from './UploadModal';

type Props = {
  fillsArray?: Array<Fill>;
  onRefresh: () => void;
  className?: string;
};
const OrderFillList = ({ fillsArray, onRefresh, className }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [receiptUrl, setReceiptUrl] = useState<string>();
  const { IcCopy, IcSearch } = useContext(ThemeContext).image;
  const [uploadId, setUploadId] = useState<string>('');
  const [unsendFillId, setUnsendFillId] = useState<string>();

  const createCopyAllString = useCallback(
    (index: number) => {
      if (!fillsArray) return '';

      const fill = fillsArray[index];

      return `${t('orderDetail.desc.accountName')} ${fill.bankAccount.name}\n${t(
        'orderDetail.desc.accountNumber',
      )} ${fill.bankAccount.account}\n${t('orderDetail.desc.total')} ${bnFormat(fill.total)}\n${t(
        'orderDetail.desc.bankName',
      )} ${fill.bankAccount.bankName}\n${t('orderDetail.desc.bankBranchName')} ${
        fill.bankAccount.bankBranchName
      }`;
    },
    [t, fillsArray],
  );

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  const onSubmitReceipt = useCallback(
    (file: File) => {
      setUploadId('');
      uploadReceipt(uploadId, file)
        .then(() => {
          onRefresh();
        })
        .catch((e) => {
          dispatch(openSnackbar({ message: e, severity: 'alert' }));
        });
    },
    [uploadId, onRefresh],
  );

  const onCheckReceipt = (fill: Fill) => {
    if (fill.receipts.length === 0) return;

    setReceiptUrl(fill.receipts[0].url);
  };

  const onUnsendClick = (id: string) => () => setUnsendFillId(id);

  const onUnsend = () => {
    if (unsendFillId === undefined) return;
    setUnsendFillId(undefined);
    unsendFill(unsendFillId)
      .then(() => {
        onRefresh();
      })
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  };

  const elementMapFunction = (orderFill: Fill, index: number) => (
    <div key={orderFill.id} className="pt-[15px]">
      {orderFill.status === 'canceled' && (
        <div className="flex flex-col gap-[5px] lg:flex-row lg:flex-wrap lg:gap-[10px]">
          <div className="flex lg:w-[calc(50%-5px)]">
            <Body size="s" className="w-1/3 text-text-neutral">
              {t('orderDetail.desc.orderId')}
            </Body>
            <Body size="s" className="w-2/3">
              {orderFill.id}
            </Body>
          </div>
          <div className="relative flex lg:w-[calc(50%-5px)]">
            <Body size="s" className="w-1/3 text-text-neutral">
              {t('orderDetail.desc.total')}
            </Body>
            <Body size="s" bold className="w-2/3 text-text-accent">
              {bnFormat(orderFill.total)}
            </Body>
            <CopyToClipboard text={bnFormat(orderFill.total)}>
              <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
      )}
      {orderFill.status !== 'canceled' && (
        <div className="flex flex-col gap-[5px] lg:flex-row lg:flex-wrap lg:gap-[10px]">
          <div className="flex lg:w-[calc(50%-5px)]">
            <Body size="s" className="w-1/3 text-text-neutral">
              {t('orderDetail.desc.orderId')}
            </Body>
            <Body size="s" className="w-2/3">
              {orderFill.id}
            </Body>
          </div>
          <div className="relative flex lg:w-[calc(50%-5px)]">
            <Body size="s" className="w-1/3 text-text-neutral">
              {t('orderDetail.desc.accountName')}
            </Body>
            <Body size="s" className="w-2/3">
              {orderFill.bankAccount.name}
            </Body>
            <CopyToClipboard text={orderFill.bankAccount.name} onCopy={onCopy}>
              <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
            </CopyToClipboard>
          </div>
          <div className="relative flex lg:w-[calc(50%-5px)]">
            <Body size="s" className="w-1/3 text-text-neutral">
              {t('orderDetail.desc.accountNumber')}
            </Body>
            <Body size="s" className="w-2/3">
              {orderFill.bankAccount.account}
            </Body>
            <CopyToClipboard text={orderFill.bankAccount.account} onCopy={onCopy}>
              <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
            </CopyToClipboard>
          </div>
          <div className="relative flex lg:w-[calc(50%-5px)]">
            <Body size="s" className="w-1/3 text-text-neutral">
              {t('orderDetail.desc.total')}
            </Body>
            <Body size="s" bold className="w-2/3 text-text-accent">
              {bnFormat(orderFill.total)}
            </Body>
            <CopyToClipboard text={bnFormat(orderFill.total)} onCopy={onCopy}>
              <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
            </CopyToClipboard>
          </div>
          <div className="relative flex lg:w-[calc(50%-5px)]">
            <Body size="s" className="w-1/3 text-text-neutral">
              {t('orderDetail.desc.bankName')}
            </Body>
            <Body size="s" className="w-2/3">
              {orderFill.bankAccount.bankName}
            </Body>
            <CopyToClipboard text={orderFill.bankAccount.bankName} onCopy={onCopy}>
              <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
            </CopyToClipboard>
          </div>
          <div className="relative flex lg:w-[calc(50%-5px)]">
            <Body size="s" className="w-1/3 text-text-neutral">
              {t('orderDetail.desc.bankBranchName')}
            </Body>
            <Body size="s" className="w-2/3">
              {orderFill.bankAccount.bankBranchName}
            </Body>
            <CopyToClipboard text={orderFill.bankAccount.bankBranchName} onCopy={onCopy}>
              <img src={IcCopy} className="absolute right-[0px] top-[0px] cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
      )}
      {orderFill.status === 'executed' && (
        <div className="mt-[15px]">
          {orderFill.rejectedAt && (
            <Body size="s" className="mb-[5px] text-right text-text-alert md:hidden">
              {t('orderDetail.desc.rejectHint')}
            </Body>
          )}
          <div className="flex justify-between gap-[5px]">
            <CopyToClipboard text={createCopyAllString(index)} onCopy={onCopy}>
              <Button appearance="outlined" size="small">
                {t('orderDetail.act.copyAll')}
              </Button>
            </CopyToClipboard>
            <div className="flex items-center justify-end gap-[5px]">
              {orderFill.rejectedAt && (
                <Body size="s" className="mb-[5px] hidden text-right text-text-alert md:block">
                  {t('orderDetail.desc.rejectHint')}
                </Body>
              )}
              <Button appearance="secondary" size="small" onClick={() => setUploadId(orderFill.id)}>
                {t('orderDetail.act.submitReceipt')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {orderFill.sentAt && (
        <div className="mt-[15px] flex justify-between">
          <div className="flex gap-[10px]">
            {orderFill.status === 'sent' && (
              <Body size="s" className="text-text-warning">
                {t('orderDetail.desc.waitingConfirm')}
              </Body>
            )}
            {orderFill.status === 'received' && (
              <Body size="s" className="text-text-success">
                {t('orderDetail.desc.confirmedBySeller')}
              </Body>
            )}
            {orderFill.status === 'sent' && (
              <Body
                size="s"
                bold
                className="h-fit cursor-pointer text-text-alert"
                onClick={onUnsendClick(orderFill.id)}
              >
                {orderFill.receipts.length > 0
                  ? t('orderDetail.desc.unsend')
                  : t('orderDetail.desc.revokeClaim')}
              </Body>
            )}
          </div>
          <div className="flex flex-col items-end">
            {orderFill.receipts.length > 0 ? (
              <Body
                size="s"
                className="flex w-fit cursor-pointer items-center"
                onClick={() => onCheckReceipt(orderFill)}
              >
                {t('orderDetail.act.checkReceipt')}
                <img src={IcSearch} />
              </Body>
            ) : (
              <Body size="s">{t('orderDetail.act.paymentClaimed')}</Body>
            )}
            {orderFill.sentAt && (
              <Body size="s">{format(Date.parse(orderFill.sentAt), 'yyyy/MM/dd HH:mm:ss')}</Body>
            )}
          </div>
        </div>
      )}
      <Divider theme="sub" className="mt-[15px]" />
    </div>
  );

  return (
    <>
      <div className={className}>{fillsArray && fillsArray.map(elementMapFunction)}</div>
      <UploadModal
        open={uploadId.length > 0}
        onClose={() => setUploadId('')}
        onSubmit={onSubmitReceipt}
      />
      {receiptUrl && (
        <Image open={true} src={receiptUrl} onClose={() => setReceiptUrl(undefined)} />
      )}
      <UnsendModal
        open={unsendFillId !== undefined}
        onClose={() => setUnsendFillId(undefined)}
        onSubmit={onUnsend}
      />
    </>
  );
};

export default OrderFillList;
