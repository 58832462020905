import { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import H3 from 'src/component/typography/H3';
import { Ui } from 'src/constant/Env';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { BankAccount } from 'src/model/Bank';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { deleteBankAccount, getAccountInfo } from 'src/service/accountService';
import AccountCard from './component/AccountCard';
import AccountTable from './component/AccountTable';
import DisableTfaModal from './component/DisableTfaModal';
import EditDisplayNameModal from './component/EditDisplayNameModal';
import InvitationModal from './component/InvitationModal';
import ResetPasswordModal from './component/ResetPasswordModal';

const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { IcEdit, IcCopy, IcQrcode } = useContext(ThemeContext).image;
  const [isEditDisplayNameModalOpen, setEditDisplayNameModalOpen] = useState<boolean>(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState<boolean>(false);
  const [isDisableTfaModalOpen, setDisableTfaModalOpen] = useState<boolean>(false);
  const [isInivationModalOpen, setInivationModalOpen] = useState<boolean>(false);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [bankAccount, setBankAccount] = useState<BankAccount[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { accountInfo: info } = useSelector((rootState: RootState) => rootState.auth);

  useEffect(() => {
    if (Ui !== 'seller' || info?.kycStatus !== 'passed') return;
    getAccountInfo()
      .then((resBankAccount) => setBankAccount(resBankAccount))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh, info]);

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  const onClickPasswordEdit = () => {
    setResetPasswordModalOpen(true);
  };

  const onClickQrcode = () => {
    setInivationModalOpen(true);
  };

  const onClickSlugEdit = () => {
    setEditDisplayNameModalOpen(true);
  };

  const onClickAdd = () => {
    navigate(Page.CreateAccount);
  };

  const onClickTfaEnable = (type: 'disable' | 'enable') => () => {
    if (type === 'enable') navigate(Page.EnableTfa);
    else setDisableTfaModalOpen(true);
  };

  const onClickDelete = (id: string) => {
    deleteBankAccount(id)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <H3 className="mt-[10px] sm:mt-[20px]">{t('account.heading')}</H3>
      <div className="mt-[28px] flex flex-wrap text-[16px]">
        <div className="box-border w-full py-[10px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="text-text-secondary">{t('account.desc.id')}</div>
          <div className="flex items-center justify-between">
            <div>{info?.id}</div>
            <CopyToClipboard onCopy={onCopy} text={info?.id ?? ''}>
              <img src={IcCopy} className="box-border h-[36px] w-[36px] cursor-pointer px-[8px]" />
            </CopyToClipboard>
          </div>
        </div>
        <div className="box-border w-full py-[10px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="text-text-secondary">{t('account.desc.nickName')}</div>
          <div className="flex items-center justify-between">
            <div>{info?.slug}</div>
            <img
              className="h-[36px] w-[36px] cursor-pointer"
              src={IcEdit}
              onClick={onClickSlugEdit}
            />
          </div>
        </div>
        <div className="box-border w-full py-[10px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="text-text-secondary">{t('account.desc.email')}</div>
          <div className="flex items-center justify-between">
            <div>{info?.email}</div>
          </div>
        </div>
        <div className="box-border w-full py-[10px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="text-text-secondary">{t('account.desc.loginPassword')}</div>
          <div className="flex items-center justify-between">
            <div>{'********'}</div>
            <img
              className="h-[36px] w-[36px] cursor-pointer"
              src={IcEdit}
              onClick={onClickPasswordEdit}
            />
          </div>
        </div>
        <div className="box-border w-full py-[10px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="text-text-secondary">{t('account.desc.kyc')}</div>
          <div className="flex items-center justify-between">
            <div>{info && t(`account.desc.kycStatus.${info.kycStatus}`)}</div>
            {info?.kycStatus !== 'passed' && (
              <Button appearance="text" onClick={() => navigate(Page.KycVerification)}>
                {t('account.act.verify')}
              </Button>
            )}
          </div>
        </div>
        <div className="box-border w-full py-[10px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="text-text-secondary">{t('account.desc.twoStepVerification')}</div>
          <div className="flex items-center justify-between">
            <div>{info?.otpActivated ? t('account.desc.enabled') : t('account.desc.disabled')}</div>
            {info && (
              <Button
                appearance="text"
                onClick={onClickTfaEnable(info.otpActivated ? 'disable' : 'enable')}
              >
                {info.otpActivated ? t('account.act.disable') : t('account.act.enable')}
              </Button>
            )}
          </div>
        </div>
        {Ui === 'seller' && info?.kycStatus === 'passed' && (
          <>
            <div className="box-border w-full py-[30px] xs:px-[15px] lg:px-[30px]">
              <Divider />
              <div className="my-[30px] box-border flex h-[56px] items-center justify-between text-text-secondary xs:w-1/2 xs:pr-[15px]">
                {t('account.desc.inviteCode')}
                <img
                  className="h-[36px] w-[36px] cursor-pointer"
                  src={IcQrcode}
                  onClick={onClickQrcode}
                />
              </div>
              <Divider />
            </div>
          </>
        )}
      </div>
      {Ui === 'seller' && info?.kycStatus === 'passed' && (
        <>
          <div className="flex items-center justify-between text-base font-bold sm:mx-[15px] lg:mx-[30px]">
            <Body size="l">{t('account.desc.paymentMethods')}</Body>
            <Button size="small" onClick={() => onClickAdd()}>
              {t('account.act.add')}
            </Button>
          </div>
          <div className="sm:mx-[15px] lg:mx-[30px]">
            {isBiggerThanMd ? (
              <AccountTable data={bankAccount} onClickDelete={onClickDelete} />
            ) : (
              <AccountCard data={bankAccount} onClickDelete={onClickDelete} />
            )}
          </div>
        </>
      )}
      <EditDisplayNameModal
        open={isEditDisplayNameModalOpen}
        handleClose={() => setEditDisplayNameModalOpen(false)}
      />
      <ResetPasswordModal
        open={isResetPasswordModalOpen}
        handleClose={() => setResetPasswordModalOpen(false)}
      />
      <DisableTfaModal
        open={isDisableTfaModalOpen}
        handleClose={() => setDisableTfaModalOpen(false)}
      />
      <InvitationModal
        open={isInivationModalOpen}
        handleClose={() => setInivationModalOpen(false)}
      />
    </div>
  );
};

export default Account;
