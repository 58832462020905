import DatePicker from 'src/component/DatePicker';
import { DateRange } from 'src/model/DateFilter';
import Body from './typography/Body';

type Props = {
  fromDate: Date;
  toDate: Date;
  setDateRange: (dateRange: DateRange) => void;
  onChange?: () => void;
};

const RoundDateFilter = ({ fromDate, toDate, setDateRange, onChange }: Props) => {
  const onCalendarChanged = (dateType: 'from' | 'to') => (newDate: Date) => {
    const newDateRange = {
      from: dateType === 'from' || newDate.getTime() < fromDate.getTime() ? newDate : undefined,
      to: dateType === 'to' || newDate.getTime() > toDate.getTime() ? newDate : undefined,
    };
    onChange && onChange();
    setDateRange(newDateRange);
  };

  return (
    <div className="mb-[15px] flex items-center gap-[10px]">
      <DatePicker value={fromDate} onChange={onCalendarChanged('from')} />
      <Body>-</Body>
      <DatePicker value={toDate} onChange={onCalendarChanged('to')} />
    </div>
  );
};

export default RoundDateFilter;
