export const Language = [
  {
    name: '简体中文',
    code: 'zh-CN',
  },
  {
    name: 'English',
    code: 'en',
  },
];
