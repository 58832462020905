import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useQuery from 'src/hook/useQuery';
import { RootState } from 'src/redux/store';
import { loadNamespace } from 'src/service/i18nService';

type Query = { mode?: string };

const TermsOfUse = () => {
  const { t } = useTranslation();
  const { workload } = useSelector((rootState: RootState) => rootState.ui);
  const query = useQuery<Query>();

  useEffect(() => {
    loadNamespace();
    if (query.mode) localStorage.setItem('theme', query.mode);
  }, []);

  if (workload > 0) return <></>;

  return (
    <div className="p-10">
      <h1>{t('app:terms.h1')}</h1>
      <p>{t('app:terms.sec-1-p-1')}</p>
      <p>{t('app:terms.sec-1-p-2')}</p>
      <p>{t('app:terms.sec-1-p-3')}</p>
      <h2>{t('app:terms.sec-2-h2')}</h2>
      <p>{t('app:terms.sec-2-p-1')}</p>
      <p>{t('app:terms.sec-2-p-2')}</p>
      <h2>{t('app:terms.sec-3-h2')}</h2>
      <p>{t('app:terms.sec-3-p-1')}</p>
      <p>{t('app:terms.sec-3-p-2')}</p>
      <h2>{t('app:terms.sec-4-h2')}</h2>
      <p>{t('app:terms.sec-4-p-1')}</p>
      <p>{t('app:terms.sec-4-p-2')}</p>
      <p>{t('app:terms.sec-4-p-3')}</p>
      <p>{t('app:terms.sec-4-p-4')}</p>
      <h2>{t('app:terms.sec-5-h2')}</h2>
      <p>{t('app:terms.sec-5-p-1')}</p>
      <p>{t('app:terms.sec-5-p-2')}</p>
      <ul>
        <li>{t('app:terms.sec-5-li-1')}</li>
        <li>{t('app:terms.sec-5-li-2')}</li>
        <li>{t('app:terms.sec-5-li-3')}</li>
        <li>{t('app:terms.sec-5-li-4')}</li>
        <li>{t('app:terms.sec-5-li-5')}</li>
        <li>{t('app:terms.sec-5-li-6')}</li>
      </ul>
      <h2>{t('app:terms.sec-6-h2')}</h2>
      <p>{t('app:terms.sec-6-p-1')}</p>
      <p>{t('app:terms.sec-6-p-2')}</p>
      <p>{t('app:terms.sec-6-p-3')}</p>
      <p>{t('app:terms.sec-6-p-4')}</p>
      <h2>{t('app:terms.sec-7-h2')}</h2>
      <ol>
        <li>{t('app:terms.sec-7-li-1')}</li>
        <li>{t('app:terms.sec-7-li-2')}</li>
        <li>{t('app:terms.sec-7-li-3')}</li>
        <li>{t('app:terms.sec-7-li-4')}</li>
        <li>{t('app:terms.sec-7-li-5')}</li>
        <li>{t('app:terms.sec-7-li-6')}</li>
        <li>{t('app:terms.sec-7-li-7')}</li>
        <li>{t('app:terms.sec-7-li-8')}</li>
        <li>{t('app:terms.sec-7-li-9')}</li>
      </ol>
      <h2>{t('app:terms.sec-8-h2')}</h2>
      <p>{t('app:terms.sec-8-p-1')}</p>
      <p>{t('app:terms.sec-8-p-2')}</p>
      <ol>
        <li>{t('app:terms.sec-8-li-1')}</li>
        <li>{t('app:terms.sec-8-li-2')}</li>
        <li>{t('app:terms.sec-8-li-3')}</li>
        <li>{t('app:terms.sec-8-li-4')}</li>
        <li>{t('app:terms.sec-8-li-5')}</li>
        <li>{t('app:terms.sec-8-li-6')}</li>
      </ol>
      <p>{t('app:terms.sec-8-p-3')}</p>
      <ol>
        <li>{t('app:terms.sec-8-li-7')}</li>
        <li>{t('app:terms.sec-8-li-8')}</li>
        <li>{t('app:terms.sec-8-li-9')}</li>
        <li>{t('app:terms.sec-8-li-10')}</li>
        <li>{t('app:terms.sec-8-li-11')}</li>
        <li>{t('app:terms.sec-8-li-12')}</li>
      </ol>
      <h2>{t('app:terms.sec-9-h2')}</h2>
      <p>{t('app:terms.sec-9-p-1')}</p>
      <p>{t('app:terms.sec-9-p-2')}</p>
      <p>{t('app:terms.sec-9-p-3')}</p>
    </div>
  );
};

export default TermsOfUse;
