import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { ThemeContext } from 'src/context/ThemeContext';
import IcBackGrey from 'src/image/ic-back.svg';

type Props = {
  color?: 'primary' | 'secondary';
  label?: string;
  navigateToPath?: string;
  onClick?: () => void;
};

const BackButton = ({ color = 'secondary', label, navigateToPath, onClick, ...props }: Props) => {
  const navigate = useNavigate();
  const { IcBack } = useContext(ThemeContext).image;
  const { t } = useTranslation();

  const onButtonClick = () => {
    if (onClick) {
      onClick();

      return;
    }
    if (navigateToPath) {
      navigate(navigateToPath);

      return;
    }
    navigate(-1);
  };

  return (
    <div>
      <Button
        appearance="text"
        className={classNames('flex items-center', {
          'text-text-neutral': color === 'secondary',
          'text-text-accent': color === 'primary',
        })}
        onClick={onButtonClick}
        {...props}
      >
        <img src={color === 'primary' ? IcBack : IcBackGrey} />
        {label || t('act.back')}
      </Button>
    </div>
  );
};

export default BackButton;
