import classNames from 'classnames';
import { isValidElement, ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Body from 'src/component/typography/Body';
import SelectContext from 'src/context/SelectContext';
import IcArrowDown from 'src/image/ic-arrow-down.svg';
import Popover from './Popover';

/**
 * usage example:
 *
 * <RoundSelect>
 *   <SelectOption value="aa">a</SelectOption>
 *   <SelectOption value="bb">b</SelectOption>
 *   <SelectOption value="cc">c</SelectOption>
 * </RoundSelect>
 */

export type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (v: string) => void;
  label?: string;
};

const RoundSelect = ({
  children,
  className,
  defaultValue,
  value: controlledSelectedValue,
  onChange,
  label,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; children: ReactNode }[]>([]);
  const [selected, setSelected] = useState<string>(defaultValue ?? '');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOptions(
      (Array.isArray(children) ? children : [children]).map((child) =>
        isValidElement(child) ? child.props : null,
      ),
    );
  }, []);

  const handleChange = (v: string) => {
    setOpen(false);
    if (controlledSelectedValue === undefined) setSelected(v);

    onChange && onChange(v);
  };

  return (
    <SelectContext.Provider value={{ current: controlledSelectedValue ?? selected, handleChange }}>
      <div
        ref={ref}
        className={twMerge(
          `box-border flex h-[30px] cursor-pointer flex-row items-center gap-[8px] rounded-[4px] border border-solid border-bg-displayfield bg-bg-displayfield px-[8px] py-[5px]`,
          classNames({ 'border-border-input-focus': !!open }),
        )}
        onClick={() => setOpen(!open)}
      >
        {label && (
          <Body size="l" className="text-text-filter-title">
            {label}
          </Body>
        )}
        <Body className={twMerge('flex items-center', className)}>
          <div>
            {options.find((v) => v.value === (controlledSelectedValue ?? selected))?.children}
          </div>
          <img className="h-[24px] w-[24px]" src={IcArrowDown} />
        </Body>
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        cssProperties={{ width: ref.current?.offsetWidth }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className="mt-[5px] py-[5px]"
      >
        {children}
      </Popover>
    </SelectContext.Provider>
  );
};

export default RoundSelect;
